import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@pmviulive/msd-reactjs";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Checkbox } from "@pmviulive/msd-reactjs";
import { userLogin, userSocialLogin } from "@pmviulive/vume-js";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { webUrl } from "../../../../config/serverConfig";
import {
  googleLoginCode,
  facebookLoginCode,
} from "../../../../config/serverConfig";
import { rememberLogin } from "../../../../config/store/localRedux/actions";
import i18n from "../../../../config/i18n";
import { checkValidation } from "./validityCheck";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";
import { isMobile } from "react-device-detect";
import {
  LogoMobileAuthDark,
  LogoMobileAuthWhite,
  LogoWebAuthDark,
  LogoWebAuthWhite,
} from "../../../../config/icons";
const SignInModal = ({
  signin,
  showSignin,
  showSignup,
  showForgetPassword,
  resetModals,
  forgetPassword,
}) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState();
  const [showLocalError, setShowLocalError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useSelector((state) => state.local.theme);
  const server = useSelector((state) => state.local.server);
  const checked = useSelector((state) => state.local.rememberLogin);
  const response = useSelector((state) => state.auth.response);
  const error = useSelector((state) => state.auth.error);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  useEffect(() => {
    if (forgetPassword === false && response !== null) {
      const forgetPasswordChangeUrl = `${server}/api/user/forgetPassword/change`;
      if (
        response &&
        response.request &&
        response.request.responseURL === forgetPasswordChangeUrl
      ) {
        return; // Do not run the effect if the responseURL matches
      }
      if (response.provider) {
        showSignin(false);
        localStorage.setItem("provider", response.provider);
        dispatch(userLogin(response.email, response.generatedPassword, server));
      }
    }
  }, [response, forgetPassword, server]);

  const handleModals = () => {
    resetModals();
    showSignup(true);
  };

  useEffect(() => {
    const forgetPasswordChangeUrl = `${server}/api/user/forgetPassword/change`;
    if (
      response &&
      response.request &&
      response.request.responseURL === forgetPasswordChangeUrl
    ) {
      return; // Do not run the effect if the responseURL matches
    } else if (error !== null && error.statusCode === 401 && isSubmitting) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.authFailed"),
      });
    }
  }, [error, server, response, dispatch, isSubmitting]);

  const handleLogin = (e) => {
    e.preventDefault();
    setShowLocalError(false); // Reset error visibility before new submission
    setIsSubmitting(true); // Mark form as submitting
    const validity = checkValidation(name.trim(), password);
    if (validity) {
      dispatch(userLogin(name, password, server));
    } else {
      setIsSubmitting(false); // Reset if validation fails
    }
  };

  const rememberMe = (e) => {
    dispatch(rememberLogin(e.target.checked));
  };

  const callSocialLogin = (email, idToken, provider, name) => {
    const socialLoginData = {
      email: email,
      idToken: idToken,
      provider: provider,
      name: name,
    };
    dispatch(userSocialLogin(socialLoginData, server));
  };

  const responseGoogle = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    const email = decoded.email;
    const idToken = credentialResponse.credential;
    const name = decoded.name;
    callSocialLogin(email, idToken, "GOOGLE");

    if (response !== null) {
      localStorage.setItem("provider", response.provider);
      dispatch(userLogin(email, response.generatedPassword, server));
      showSignin(false);
    }
  };

  const responseFacebook = (response) => {
    callSocialLogin(response.email, response.accessToken, "FACEBOOK");
  };

  const appleLogin = async () => {
    window.AppleID.auth.init({
      clientId: "app.millennium.vumemobile",
      scope: "email name",
      redirectURI: webUrl,
      usePopup: true,
    });

    try {
      const data = await window.AppleID.auth.signIn();
      if (data.user) {
        const fullName =
          data.user.name.firstName + " " + data.user.name.lastName;
        callSocialLogin(
          data.user.email,
          data.authorization.id_token,
          "APPLE",
          fullName
        );
      } else {
        callSocialLogin(null, data.authorization.id_token, "APPLE");
      }
    } catch (error) {}
  };

  const handleForgetPassword = () => {
    showSignin(false);
    showForgetPassword(true);
  };

  return (
    <Modal
      centered
      visible={signin}
      footer={false}
      onCancel={() => {
        resetModals();
        setIsSubmitting(false); // Reset submission state when modal closes
        setShowLocalError(false); // Reset error state when modal closes
      }}
      // className={`auth-modal ${
      //   theme === "dark" ? "signin-modal-dark" : "signin-modal-light"
      // }`}
      className={`auth-modal ${
        theme === "dark" ? "signin-modal-dark" : "signin-modal-light"
      } ${isMobile ? "auth-modal-mobile" : ""}`}
      width={isMobile ? "312px" : "472px"}
    >
      <h1
        className="form-title"
        style={
          isMobile
            ? {
                fontSize: "20px",
                fontWeight: "600",
                marginBottom: "10px",
                lineHeight: "16px"
              }
            : null
        }
      >
        {i18n.t("text.SignInTo")}{" "}
        {isMobile ? (
          theme === "dark" ? (
            <LogoMobileAuthDark />
          ) : (
            <LogoMobileAuthWhite />
          )
        ) : theme === "dark" ? (
          <LogoWebAuthDark />
        ) : (
          <LogoWebAuthWhite />
        )}
      </h1>
      <form className="form-container" onSubmit={handleLogin}>
        <input
          onChange={(e) => setName(e.target.value)}
          className="auth-input"
          type="email"
          placeholder={i18n.t("text.emailAdress")}
          style={
            isMobile
              ? {
                  height: "32px",
                  padding: "16px",
                  marginTop: "16px",
                }
              : null
          }
        />
        <input
          onChange={(e) => setPassword(e.target.value)}
          className="auth-input"
          type="password"
          placeholder={i18n.t("text.password")}
          style={
            isMobile
              ? {
                  height: "32px",
                  padding: "16px",
                  marginTop: "16px",
                }
              : null
          }
        />
        <div
          className="checkbox-contanier"
          style={
            isMobile
              ? {
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "22px",
                  marginTop: "8px",
                }
              : null
          }
        >
          <Checkbox
            onChange={rememberMe}
            checked={checked}
            className="remember-me"
            style={
              isMobile
                ? {
                    fontSize: "12px",
                  }
                : null
            }
          >
            {i18n.t("text.rememberMe")}
          </Checkbox>
          <p
            style={
              isMobile
                ? {
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    marginTop: "8px",
                  }
                : null
            }
            onClick={handleForgetPassword}
          >
            {i18n.t("text.forgotPassword")}?
          </p>
        </div>
        <button
          type="submit"
          style={
            isMobile
              ? {
                  height: "40px",
                  // padding: "16px",
                  fontSize: "16px",
                  marginTop: "24px",
                }
              : null
          }
        >
          {i18n.t("text.SignIn")}
        </button>
      </form>
      <div
        className="social-icon-container"
        style={
          isMobile
            ? {
                marginTop: "48px",
              }
            : null
        }
      >
        <FacebookLogin
          appId={facebookLoginCode}
          autoLoad={false}
          callback={responseFacebook}
          redirectUri={webUrl}
          fields="email"
          disableMobileRedirect={true}
          render={(renderProps) => (
            <img
              src="/social/facebook.svg"
              alt="facebook"
              onClick={renderProps.onClick}
            />
          )}
        />

        <GoogleLogin
          onSuccess={(credentialResponse) => {
            responseGoogle(credentialResponse);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          shape="circle"
          type="icon"
        />

        <img
          src={
            theme === "dark" ? "/social/apple.svg" : "/social/light-apple.svg"
          }
          alt="apple"
          onClick={appleLogin}
        />
      </div>
      <div className="signup-redirect">
        {i18n.t("text.noAccount")}{" "}
        <span 
          style={{
            textDecoration: "underline",
          }}
        onClick={handleModals}>{i18n.t("text.SignUp")}</span>
      </div>
    </Modal>
  );
};

export default SignInModal;
