import React from "react";
import { useSelector } from "react-redux";
import "./notificationTab.scss";

const tabStyles = {
  fontSize: "15px",
  padding: "0px" ,
  margin: "0px" ,
  fontWeight: 700,
  textTransform: "capitalize"
}
const NotificationTab = ({ activeTab, changeTab }) => {
  const notificationLoading = useSelector(
    (state) => state.notifications.loading
  );

  return (
    <div className="Notification-tab-container">
      <div
        className="notification-tab"
        onClick={
          activeTab !== "all" && !notificationLoading
            ? () => changeTab("all")
            : () => {}
        }
        style={{ backgroundColor: activeTab === "all" ? "#5c44af" : " #fff" }}
      >
        <p
          // className="notification-tab-text"
          style={{ ...tabStyles,color: activeTab === "all" ? "#fff" : " #333" }}
        >
          All
        </p>
      </div>

      <div
        className="notification-tab"
        onClick={
          activeTab !== "new" && !notificationLoading
            ? () => changeTab("new")
            : () => {}
        }
        style={{ backgroundColor: activeTab === "new" ? "#5c44af" : " #fff" }}
      >
        <p
          // className="notification-tab-text"
          style={{ ...tabStyles,color: activeTab === "new" ? "#fff" : " #333" }}
        >
          Unread
        </p>
      </div>

      <div
        className="notification-tab"
        onClick={
          activeTab !== "today" && !notificationLoading
            ? () => changeTab("today")
            : () => {}
        }
        style={{ backgroundColor: activeTab === "today" ? "#5c44af" : " #fff" }}
      >
        <p
          // className="notification-tab-text"
          style={{ ...tabStyles, color: activeTab === "today" ? "#fff" : " #333" }}
        >
          Today
        </p>
      </div>
    </div>
  );
};

export default NotificationTab;
