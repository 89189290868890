import React, { Component,createContext  } from "react";
import { getLocations, getAllCategories } from "@pmviulive/vume-js";
import {
  saveToken,
  saveServer,
  saveLocations,
  saveCategories,
  saveTheme,
} from "./config/store/localRedux/actions";
import { Spinner, Modal } from "@pmviulive/msd-reactjs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Routes from "./routes/routes";
import { Offline } from "@pmviulive/react-detect-offline";
import Layout from "./view/layout/layout";
import { googleRecaptchaCode, googleApiAddress } from "./config/serverConfig";
import { serverError } from "./config/errorHandler";
import ErrorBoundaries from "./config/errorBoundary";
import ReactGA from "react-ga";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { v4 as uuidv4 } from "uuid";
import { getServerBasedOnPoints, getClosestServer } from "@pmviulive/msd-js";
import * as Sentry from "@sentry/react";
import "./root.scss";
import * as firebase from "firebase/app";
import "firebase/analytics";
import {isMobile} from "react-device-detect";
import MyProvider, { MyContext } from "./view/sharedComponents/MyProvider/MyProvider";
import { googleLoginCode } from "./config/serverConfig";
import { GoogleOAuthProvider } from '@react-oauth/google';


class App extends Component {
  state = {
    isOnline: true,
  };
  componentDidMount() {
    if (window.location.search.includes("?privacy=true")) {
      this.props.history.push("/privacy");
    }
    const theme = localStorage.getItem("theme") || "light";
    this.props.saveTheme(theme);
    let serialNumber = localStorage.getItem("serialNumber");
    if (serialNumber) {
    } else {
      serialNumber = uuidv4();
      localStorage.setItem("serialNumber", serialNumber);
    }
    if (
      localStorage.getItem("user") &&
      !localStorage.getItem("token") &&
      !sessionStorage.getItem("token")
    ) {
      localStorage.clear();
    }
    if (sessionStorage.getItem("server")) {
      this.props.saveServer(sessionStorage.getItem("server"));
      this.initial(sessionStorage.getItem("server"));
    } else {
      this.getClosestServer();
      if ("geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition((position) => {
            this.getServerBasedOnPoints(
              position.coords.latitude,
              position.coords.longitude
            );
          }, this.getClosestServer);
        } catch (err) {
           console.log(err);
        }
      } else {
        this.getClosestServer();
      }
    }

    const environment = process.env.REACT_APP_API_HOST.includes("dev")
      ? "dev-viulive-web"
      : process.env.REACT_APP_API_HOST.includes("stg")
      ? "stg-viulive-web"
      : "prd-viulive-web";
    if (
      process.env.REACT_APP_IS_PRODUCTION === "true" ||
      process.env.REACT_APP_LEVEL === "stg"
    ) {
      Sentry.init({
        dsn: "https://73dd136923cb43ac82423f5d3710e077@o417594.ingest.sentry.io/5318485",
        enableAutoSessionTracking: true,
        environment: environment,
      });
    }

    // try {
    //   window.addEventListener("offline", () => this.offline());
    //   window.addEventListener("online", () => this.online());
    // } catch (e) {
    //   console.log(e);
    // }

  }
  offline = () => {
    this.setState({
      isOnline: false,
    });
  };
  online = () => {
    if (!this.state.isOnline) {
      this.setState({
        isOnline: true,
      });
      this.forceUpdate();
    }
  };
  getServerBasedOnPoints = async (lat, lon) => {
    const result = await getServerBasedOnPoints(
      lat,
      lon,
      process.env.REACT_APP_SERVER_LOCATION.split(","),
      process.env.REACT_APP_API_HOST.split(","),
      process.env.REACT_APP_WEBSOCKET_HOST.split(",")
    );
    this.getClosestServer(result);
  };
  getClosestServer = async (closestServer) => {
    let apiHosts = process.env.REACT_APP_API_HOST.split(",");
    let socketHosts = process.env.REACT_APP_WEBSOCKET_HOST.split(",");
    if (closestServer) {
      apiHosts.push(closestServer[0]);
      socketHosts.push(closestServer[1]);
    }
    const result = await getClosestServer(apiHosts, socketHosts);

    if (result) {
      this.props.saveServer(result[0]);
      sessionStorage.setItem("server", result[0]);
      sessionStorage.setItem("ws", result[1]);
      this.initial(result[0]);
    }
  };
  initial = (server) => {
    ReactGA.initialize("UA-149363161-1", {
      debug: false,
    });

    const firebaseConfig = {
      apiKey: "AIzaSyCKi70Qu_vBcOJ1blG7pCr93Q3M_7-7u7s",
      authDomain: "viulive-254807.firebaseapp.com",
      databaseURL: "https://viulive-254807.firebaseio.com",
      projectId: "viulive-254807",
      storageBucket: "viulive-254807.appspot.com",
      messagingSenderId: "769287433043",
      appId: "1:769287433043:web:91090ad6b873752d0a8fa6",
      measurementId: "G-5YX3DJP7P0",
    };
    if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

    if (process.env.REACT_APP_IS_PRODUCTION === "true") {
      firebase.analytics();
    }
    this.props.getLocations(server);
    this.props.getAllCategories(server);
    this.props.saveToken();
  };
  componentDidUpdate(prevProps) {
    // if (process.env.REACT_APP_LEVEL !== "dev") {
    //   // console.clear();
    // }
    if (this.props.history.location.pathname !== prevProps.history.pathname) {
      ReactGA.pageview(this.props.history.location.pathname);
    }
    if (this.props.locations && prevProps.locations !== this.props.locations) {
      localStorage.setItem("locations", JSON.stringify(this.props.locations));
      this.getCurrentLocation();
      this.props.saveLocations(this.props.locations);
    }
    if (
      this.props.categories &&
      prevProps.categories !== this.props.categories
    ) {
      this.props.saveCategories(this.props.categories);
    }
    if (
      this.props.serverError &&
      this.props.serverError !== prevProps.serverError
    ) {
      if (navigator.onLine) {
        serverError();
      }
    }
    if (
      this.props.permissionError &&
      this.props.permissionError !== prevProps.permissionError
    ) {
      this.props.history.push("/noPermission");
    }
  }

  getCurrentLocation = () => {
    if (!this.props.history.location.pathname.includes("recaptcha")) {
      if ("geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition((position) => {
            const localApi = googleApiAddress(position);
            fetch(localApi)
              .then((response) => {
                return response.json();
              })
              .then((json) => {
                if (json.results.length > 0) {
                  json.results.map((location) => {
                    if (location.types.includes("country")) {
                      this.props.localLocations.map((country) => {
                        if (country.name === location.formatted_address) {
                          sessionStorage.setItem("myLocation", country.id);
                        }
                        return null;
                      });
                    }
                    return null;
                  });
                } else {
                  return null;
                }
              });
          }, this.errorNavigatorHandler);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
  render() {
    const urlSearch = new URLSearchParams(window.location.search);
    
    return (
      <ErrorBoundaries>
        {this.props.server && this.props.categories ? (
          <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaCode}>
            <GoogleOAuthProvider clientId={googleLoginCode}>
            <MyProvider>
              <MyContext.Consumer>
                {({tooltip, setTooltip}) => (
                  <Layout
                    hideLayout={urlSearch.get("hideLayout") ? true : false}
                    theme={this.props.theme}
                    quickLiveTooltip={tooltip.quickLiveTooltip}
                    setTooltips={setTooltip}
                  >
                    {/*<Offline polling={{url:"https://www.viulive.com"}}>*/}
                    {!isMobile &&
                      <Offline>
                          <Modal
                              visible={true}
                              footer={null}
                              closable={false}
                              className={`${
                                  this.props.theme === "dark" ? "darkModal" : ""
                              }`}
                          >
                            <div className={`theme theme--${this.props.theme || "light"}`}>
                              <div className="offlineMessage">
                                <p>You are currently offline.</p>
                                <span onClick={() => this.forceUpdate()}>Refresh</span>
                              </div>
                            </div>
                          </Modal>
                      </Offline>
                    }
                    <Routes />
                </Layout>
                )}
              </MyContext.Consumer>
            </MyProvider>
            </GoogleOAuthProvider>
          </GoogleReCaptchaProvider>
        ) : (
          <Spinner className="absoluteLoader" />
        )}
      </ErrorBoundaries>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locations: state.locations.locations,
    categories: state.events.categories,
    serverError: state.errorHandler.response,
    permissionError: state.errorHandler.permissionError,
    server: state.local.server,
    localLocations: state.local.locations,
    token: state.local.token,
    theme: state.local.theme,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: (server) => dispatch(getLocations(server)),
    getAllCategories: (server) => dispatch(getAllCategories(server)),
    saveToken: () => dispatch(saveToken()),
    saveServer: (server) => dispatch(saveServer(server)),
    saveLocations: (locations) => dispatch(saveLocations(locations)),
    saveCategories: (categories) => dispatch(saveCategories(categories)),
    saveTheme: (theme) => dispatch(saveTheme(theme)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
