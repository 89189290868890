import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { asyncComponent } from "@pmviulive/msd-reactjs";
import { isMobile, isTablet } from "react-device-detect";
import { notFound, accessDenied } from "@pmviulive/vume-reactjs";
// =====================================================

const asyncLandingPage = asyncComponent(() => {
  return import("../view/modules/landingPage/landingPage");
});
const asyncSearchResultPage = asyncComponent(() => {
  return import("../view/modules/searchResult/searchResult");
});
const asyncWatchPage = asyncComponent(() => {
  return import("../view/modules/watch/watchWrapper");
});
const asyncDetailPage = asyncComponent(() => {
  return import("../view/modules/eventDetail/eventDetail");
});
const asyncBroadcast = asyncComponent(() => {
  return import("../view/modules/broadcast/broadcast");
});
const asyncScheduleConfirm = asyncComponent(() => {
  return import(
    "../view/modules/broadcast/components/scheduleConfirm/scheduleConfirm"
  );
});
const asyncReactionView = asyncComponent(() => {
  return import("../view/modules/reactionsView/reactionView");
});
const asyncConfirmJoin = asyncComponent(() => {
  return import("../view/modules/broadcast/components/joinConfirm/joinConfirm");
});
const asyncCapture = asyncComponent(() => {
  return import("../view/modules/capturing/capturing");
});
const asyncMyEvent = asyncComponent(() => {
  return import("../view/modules/myEvent/myEvent");
});
const asyncEditCamera = asyncComponent(() => {
  return import("../view/modules/broadcast/components/member/member");
});
const asyncMobileSearch = asyncComponent(() => {
  return import("../view/layout/search/mobileSearchPage");
});
const asyncUserProfile = asyncComponent(() => {
  return import("../view/modules/userProfile/");
});

const asyncPremium = asyncComponent(() => {
  return import("../view/modules/premium/premium");
});
const asyncMobileLoginByGoogle = asyncComponent(() => {
  return import("../view/modules/auth/mobileLoginByGoogle/mobileLoginByGoogle");
});

const asyncloginResult = asyncComponent(() => {
  return import("../view/modules/auth/mobileLoginByGoogle/loginResult");
});
const asyncEndedEvents = asyncComponent(() => {
  return import("../view/modules/watch/components/endedEvents/endedEvents");
});
const asyncFinishBroadcast = asyncComponent(() => {
  return import(
    "../view/modules/broadcast/components/finishBroadcast/finishBroadcast"
  );
});
const asyncReCaptchaV3 = asyncComponent(() => {
  return import("../view/modules/reCaptchaV3/reCaptchaV3");
});
const asyncReCaptchaV2 = asyncComponent(() => {
  return import("../view/modules/reCaptchaV2/reCaptchaV2");
});
const asyncDownoadApp = asyncComponent(() => {
  return import("../view/sharedComponents/downloadApp/downloadApp");
});
const asyncPrivacyPage = asyncComponent(() => {
  return import("../view/modules/privacy/privacy");
});
const asyncFAQPage = asyncComponent(() => {
  return import("../view/modules/faq/faq");
});
const asyncTermsPage = asyncComponent(() => {
  return import("../view/modules/termsAndConditions/termsAndConditions");
});
const asyncNoPermission = asyncComponent(() => {
  return import("../view/sharedComponents/errors/noPermission");
});
const asyncNotFoundData = asyncComponent(() => {
  return import("../view/sharedComponents/errors/notFound");
});
const asyncLogout = asyncComponent(() => {
  return import("../view/modules/auth/logout/logout");
});
// const asyncFollowing = asyncComponent(() => {
//   return import("../view/modules/followersAndFollowing/following");
// });
// const asyncFollowers = asyncComponent(() => {
//   return import("../view/modules/followersAndFollowing/followers");
// });
// const asyncMixer = asyncComponent(() => {
//   return import("../view/modules/mixer/mixerWrapper");
// });
// const asyncDirector = asyncComponent(() => {
//   return import("../view/modules/mixer/director");
// });
const asyncSetting = asyncComponent(() => {
  return import("../view/modules/setting/setting");
});
const asyncMyProfile = asyncComponent(() => {
  return import("../view/modules/myProfile/myProfile");
});

const asyncTransactionsHistory = asyncComponent(() => {
  return import(
    "../view/modules/myProfile/components/transactionsHistory/transactionsHistory"
  );
});
const asyncWithdraw = asyncComponent(() => {
  return import("../view/modules/myProfile/components/withdraw/withdraw");
});

const asyncDirector = asyncComponent(() => {
  return import("../view/modules/director/director");
});

const asyncSafetySection = asyncComponent(() => {
  return import("../view/modules/safetySection/safetySection");
});
// const asyncTopUp = asyncComponent(() => {
//   return import("../view/modules/topUp/topUp");
// });
const routes = () => {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const createRedirect = (to) => () => <Redirect to={to} />;
  if (userInfo) {
    return (
      <Switch>
        <Route path="/" exact component={asyncLandingPage}></Route>
        <Route path="/register" component={asyncLandingPage}></Route>
        <Route path="/director" component={asyncDirector} key={Date.now()}></Route>
        <Route path="/safetysection" component={asyncSafetySection}></Route>
        <Route
          path="/result"
          exact
          component={
            isMobile || isTablet ? asyncMobileSearch : asyncSearchResultPage
          }
        ></Route>
        <Route
          path="/watch/:id"
          component={asyncWatchPage}
          exact
          key={Date.now()}
        ></Route>
        <Route path="/ended/:id" component={asyncEndedEvents}></Route>
        <Route path="/finish/:id" component={asyncFinishBroadcast}></Route>
        <Route
          path="/detail/:id"
          exact
          component={asyncDetailPage}
          key={Date.now()}
        ></Route>
        <Route
          path="/advancedLive"
          exact
          component={isMobile || isTablet ? asyncDownoadApp : asyncBroadcast}
        ></Route>
        <Route
          path="/teamadmin"
          exact
          component={isMobile || isTablet ? asyncDownoadApp : asyncBroadcast}
        ></Route>
        <Route path="/broadcast/:id" component={asyncBroadcast}></Route>

        <Route path="/confirm/:id" component={asyncScheduleConfirm}></Route>
        <Route path="/confirmJoin/:id" component={asyncConfirmJoin}></Route>
        <Route
          path="/capture/:id"
          component={isMobile || isTablet ? asyncDownoadApp : asyncCapture}
        ></Route>
        <Route path="/Reactions/:id" component={asyncReactionView}></Route>
        {/* <Route path="/myevents/" component={asyncMyEvent}></Route> */}
        {/* <Route path="/topup" component={asyncTopUp}></Route> */}
        <Route
          path="/user/:id"
          component={asyncUserProfile}
          key={Date.now()}
        ></Route>

        {/* <Route path="/followers/:id" component={asyncFollowers}></Route>
        <Route path="/following/:id" component={asyncFollowing}></Route> */}
        <Route path="/editCamera/:id" component={asyncEditCamera}></Route>
        <Route
          path="/capture"
          component={isMobile || isTablet ? asyncDownoadApp : asyncCapture}
        ></Route>

        <Route
          path="/join"
          component={isMobile || isTablet ? asyncDownoadApp : asyncEditCamera}
        ></Route>
        <Route path="/premium" component={asyncPremium}></Route>
        <Route path="/setting" exact>
          <Redirect to="/setting/edit" />
        </Route>
        <Route path="/setting/:id" component={asyncSetting}></Route>

        <Route path="/profile" exact>
          <Redirect to="/profile/viubit" />
        </Route>
        <Route
          path="/profile/:id"
          component={asyncMyProfile}
          key={Date.now()}
        ></Route>
        <Route
          path="/profile/transactions"
          component={asyncTransactionsHistory}
        ></Route>
        <Route path="/profile/withdraw" component={asyncWithdraw}></Route>

        <Route path="/accessdenied" component={accessDenied}></Route>
        <Route path="/privacy" component={asyncPrivacyPage}></Route>
        <Route path="/faq" component={asyncFAQPage}></Route>

        <Route path="/terms" component={asyncTermsPage}></Route>
        <Route path="/noPermission" component={asyncNoPermission}></Route>
        <Route path="/notFound" component={asyncNotFoundData}></Route>

        <Route
          path="/loginByGoogle"
          component={asyncMobileLoginByGoogle}
        ></Route>
        <Route path="/loginresult" component={asyncloginResult}></Route>
        <Route path="/recaptchav3" component={asyncReCaptchaV3}></Route>
        <Route path="/recaptchav2" component={asyncReCaptchaV2}></Route>
        <Route path="/logout" component={asyncLogout}></Route>
        {/* <Route path="/mixer/:id" component={asyncMixer}></Route>

        <Route
          path="/director/"
          component={isMobile || isTablet ? asyncDownoadApp : asyncDirector}
        ></Route> */}

        <Route component={notFound} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/" exact component={asyncLandingPage}></Route>
        <Route path="/register" component={asyncLandingPage}></Route>
        <Route
          path="/result"
          exact
          component={
            isMobile || isTablet ? asyncMobileSearch : asyncSearchResultPage
          }
        ></Route>
        <Route
          path="/watch/:id"
          component={asyncWatchPage}
          exact
          key={Date.now()}
        ></Route>
        <Route path="/ended/:id" component={asyncEndedEvents}></Route>
        <Route
          path="/detail/:id"
          component={asyncDetailPage}
          exact
          key={Date.now()}
        ></Route>
        <Route
          path="/user/:id"
          component={asyncUserProfile}
          key={Date.now()}
        ></Route>

        <Route path="/accessdenied" component={accessDenied}></Route>
        <Route
          path="/loginByGoogle"
          component={asyncMobileLoginByGoogle}
        ></Route>
        <Route path="/loginresult" component={asyncloginResult}></Route>
        <Route path="/recaptchav3" component={asyncReCaptchaV3}></Route>
        <Route path="/recaptchav2" component={asyncReCaptchaV2}></Route>
        <Route path="/privacy" component={asyncPrivacyPage}></Route>
        <Route path="/faq" component={asyncFAQPage}></Route>

        <Route path="/terms" component={asyncTermsPage}></Route>
        <Route path="/notFound" component={asyncNotFoundData}></Route>
        <Route path="/safetysection" component={asyncSafetySection}></Route>
        <Route component={createRedirect("/")} />
      </Switch>
    );
  }
};

export default withRouter(routes);
