import React, { useEffect, useRef, useState } from "react";
import "./NavigationMenu.scss"; // Import the SCSS file
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import InviteFriends from "../../sharedComponents/inviteFriends/inviteFriends";
import SignInModal from "../../modules/auth/signin/signInModal";
import Signup from "../../modules/auth/signup/signup";
import ForgotPassword from "../../modules/auth/forgotPassword/forgotPassword";
import { withRouter } from "react-router-dom";
import MobileSidebar from "../sidebar/mobileSidebar";
import Notification from "../../modules/notification/notification";
import {
  InviteFriendsMobileDark,
  InviteFriendsMobileWhite,
} from "../../../config/icons";
import { Avatar } from "@pmviulive/msd-reactjs";

export const menuItems = [
  {
    text: "Privacy",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c2fce79e3261211e4024f0767890fb3c1799cfaba9551768723288deb741cc41?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398",
    onClick: () => (window.location.href = "/privacy"),
  },
  {
    text: "Terms",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c2fce79e3261211e4024f0767890fb3c1799cfaba9551768723288deb741cc41?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398",
    onClick: () => (window.location.href = "/terms"),
  },
  {
    text: "Blog",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c2fce79e3261211e4024f0767890fb3c1799cfaba9551768723288deb741cc41?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398",
    onClick: () => window.open("http://blog.viulive.com/", "_blank"),
  },
  {
    text: "FAQ",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c2fce79e3261211e4024f0767890fb3c1799cfaba9551768723288deb741cc41?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398",
    onClick: () => (window.location.href = "/faq"),
  },
];

export const socialIcons = [
  {
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/e0e18511b9b7405134c1bbfa0f223fdea493c3c8a3edf6253bceecc02e1f1f47?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398",
    alt: "Facebook",
    link: "https://www.facebook.com/Viuliveapp-106889638527748",
  },
  {
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/5270ae27d80717efe905f2e363b69076663446287136994ccc3efdfc5cb803c6?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398",
    alt: "Instagram",
    link: "https://www.instagram.com/viulive.app/",
  },
  {
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/445357a48d18e84ea97af657d91bb11bf21093859cc317ad8ba4b9e90fb4b6df?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398",
    alt: "Twitter",
    link: "https://twitter.com/Viuliveapp",
  },
];

const HeaderMobile = (props) => {
  let theme = props.theme;
  let popupHandler = props.popupHandler;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const token = useSelector((state) => state.local.token);
  const [signin, setSignin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const handleProfileClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const themeClass = theme === "dark" ? "darkTheme" : "lightTheme";

  const handleClickOnSearch = () => {
    history.push(`/result?`);
  };

  useEffect(() => {
    token ? setLoggedIn(true) : setLoggedIn(false);
  }, [token]);

  const resetModals = () => {
    setSignin(false);
    setSignup(false);
    setForgetPassword(false);
  };

  const user = localStorage.getItem("user") ? localStorage.getItem("user") : null;
  const avatarURL = user ? JSON.parse(user)?.avatar?.url : "";

  return (
    <div className={themeClass}>
      <header className="headerMobile">
        {theme === "dark" ? (
          <a href="/">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ed2ec0fe373af838eccc505e99d4a9f42538bc76d4021ca59bf0a67d26c05955?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398"
              className="logo"
              alt="Company Logo"
              loading="lazy"
            />
          </a>
        ) : (
          <a href="/">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c29068f07085d20497764caca33b136a9d656e38c645bedb7713f5b93f9e0fb?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398"
              alt="Company Logo"
              className="logo"
              loading="lazy"
            />
          </a>
        )}
        <div className="headerActions">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/10c650681d77deb5fa6a58d6a2d326c2e16a58db1ea6a17f349e5d315bb4c7e0?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398"
            className="actionIcon"
            alt=""
            loading="lazy"
            onClick={handleClickOnSearch}
          />

          {loggedIn ? <Notification /> : null}
          <Avatar
            src={
              avatarURL
                ? avatarURL
                : "https://cdn.builder.io/api/v1/image/assets/TEMP/b1a36948c81819adb62d7540b6a58c0a817159814025ce3b4409e4e611500823?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398"
            }
            className="profileIcon"
            alt="Profile"
            loading="lazy"
            onClick={handleProfileClick}
          />
        </div>
      </header>

      {dropdownVisible && (
        <nav className="menu" ref={dropdownRef}>
          {loggedIn ? (
            <div className="authSection">
              <button
                className="authButton loginButton"
                onClick={() => {
                  history.push("/setting");
                  setDropdownVisible(false);
                }}
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcb08069d714aa06c96d82b448d529f0185c01ef6834fec5448cd07f75503883?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398"
                  alt=""
                  className="settingsIcon"
                  loading="lazy"
                />
                <span 
                  style={{
                    marginLeft: "5px",
                  }}
                >Settings</span>
              </button>
            </div>
          ) : (
            <>
              {" "}
              <div className="authSection">
                <button
                  className="authButton loginButton"
                  onClick={() => {
                    resetModals();
                    setSignin(true);
                    setDropdownVisible(false);
                  }}
                >
                  Sign In
                </button>
                <button
                  className="authButton signUpButton"
                  onClick={() => {
                    resetModals();
                    setSignup(true);
                    setDropdownVisible(false);
                  }}
                >
                  Sign up
                </button>
              </div>
            </>
          )}

          <div className="divider" />

          <div>
            {menuItems.map((item, index) => (
              <div
                className="menuItem"
                role="button"
                key={index}
                onClick={() => {
                  item.onClick();
                  setDropdownVisible(false);
                }}
              >
                <span className="menuItemText">{item.text}</span>
                <img
                  src={item.iconSrc}
                  className="menuItemIcon"
                  alt={item.text}
                  loading="lazy"
                />
              </div>
            ))}
          </div>

          <div className="divider" />

          <div className="socialLinks">
            {socialIcons.map((icon, index) => (
              <a
                key={index}
                href={icon.link}
                target="_blank"
                rel="noopener noreferrer"
                className="socialIcon"
              >
                <img
                  src={icon.iconSrc}
                  className="socialIcon"
                  alt={icon.alt}
                  loading="lazy"
                />
              </a>
            ))}
          </div>

          <button
            className="inviteButton"
            onClick={() => {
              popupHandler(<InviteFriends />);
            }}
          >
            {theme === "dark" ? (
              <InviteFriendsMobileDark />
            ) : (
              <InviteFriendsMobileWhite />
            )}
            <span>Invite Friends</span>
          </button>

          <footer className="copyright">
            <div>Copyright © 2020 ViuCom</div>
            <div className="companyName">Beyond Apps Group</div>
          </footer>
        </nav>
      )}
      <MobileSidebar
        visible={props.collapsed}
        handleDrawerClose={props.handleDrawerClose}
        handleDrawerOpen={props.handleDrawerOpen}
        popupHandler={(component, logo) => props.popupHandler(component, logo)}
        closePopup={props.closePopup}
      />
      {signin && (
        <SignInModal
          theme={theme}
          signin={signin}
          showSignin={setSignin}
          showSignup={setSignup}
          showForgetPassword={setForgetPassword}
          resetModals={resetModals}
          forgetPassword={forgetPassword}
        />
      )}
      {signup && (
        <Signup
          theme={theme}
          signup={signup}
          showSignup={setSignup}
          resetModals={resetModals}
        />
      )}
      {forgetPassword && (
        <ForgotPassword
          theme={theme}
          forgetPassword={forgetPassword}
          showForgetPassword={setForgetPassword}
          resetModals={resetModals}
        />
      )}
    </div>
  );
};

export default withRouter(HeaderMobile);
