import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Logo } from "@pmviulive/msd-reactjs";
import { MobileView, BrowserView, isMobile } from "react-device-detect";
import MobileSidebar from "../sidebar/mobileSidebar";
import Search from "../search/search";
import Notification from "../../modules/notification/notification";
import { QuestionMark } from "../../../config/icons";
import RealTimeTipsModal from "../../modules/director/components/RealTimeTipsModal/RealTimeTipsModal";
import { MyContext } from "../../sharedComponents/MyProvider/MyProvider";
import ForgotPassword from "../../modules/auth/forgotPassword/forgotPassword";
import Signup from "../../modules/auth/signup/signup";
import SignInModal from "../../modules/auth/signin/signInModal";
import i18n from "../../../config/i18n";

import "./header.scss";
import HeaderMobile from "./headerMobile";

const HeaderContent = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [quickGuide, setQuickGuide] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [signin, showSignin] = useState(false);
  const [signup, showSignup] = useState(false);
  const [forgetPassword, showForgetPassword] = useState(false);
  const token = useSelector((state) => state.local.token);
  const theme = useSelector((state) => state.local.theme);
  const { setTooltip } = useContext(MyContext);

  useEffect(() => {
    token ? setLoggedIn(true) : setLoggedIn(false);
  }, [token]);

  const handleClickOnSearch = () => {
    props.history.push(`/result?`);
  };

  //TO PREVENT THE ONBOARDING VIDEO FROM SHOWING UP IN EVERY PLACE
  useEffect(() => {
    if (props.history.location.pathname !== "/director") {
      setModalVisible(false);
      setTooltip({ quickLiveTooltip: false });
    }
  }, [props.history.location.pathname]);

  useEffect(() => {
    setModalVisible(false);
  }, []);

  const resetModals = () => {
    showSignin(false);
    showSignup(false);
    showForgetPassword(false);
  };

  return isMobile ? (
    <HeaderMobile
      history={props.history}
      location={props.location}
      collapsed={props.collapsed}
      handleDrawerClose={props.handleDrawerClose}
      handleDrawerOpen={props.handleDrawerOpen}
      popupHandler={(component, logo) => props.popupHandler(component, logo)}
      closePopup={props.closePopup}
      theme={theme}
    />
  ) : (
    <React.Fragment>
      <Col lg={7} xs={11} md={7} sm={6}>
        <div className="vume-header__logo">
          {/* <div className={"viu-sideBtn closed"} /> */}
          <div
            className={
              props.collapsed ? "viu-sideBtn closed" : "viu-sideBtn  opend"
            }
            onClick={
              !props.collapsed
                ? props.handleDrawerOpen
                : props.handleDrawerClose
            }
          >
            <span className="viu-sideBtn__span span--one"></span>
            <span className="viu-sideBtn__span span--two"></span>
            <span className="viu-sideBtn__span span--three"></span>
          </div>
          <a href="/">
            <Logo
              style={{ width: "120px" }}
              src={
                process.env.PUBLIC_URL +
                `/images/${
                  props.theme === "dark" ? "vumeLogoDark" : "vumeLogo"
                }.png`
              }
            />
          </a>
        </div>
      </Col>
      <Col md={10} /* xs={4}  */ xs={11} sm={15}>
        <MobileView>
          <span
            className="mobileSearchIcon"
            style={{ textAlign: "right", float: "right" }}
            onClick={handleClickOnSearch}
          >
            <i className="icon-search-box"></i>
          </span>
        </MobileView>
        <BrowserView>
          <Search />
        </BrowserView>
      </Col>
      <Col /* xs={3}  */ md={5} xl={4} xs={2}>
        <MobileView style={{ textAlign: "right" }}>
          <MobileSidebar
            visible={props.collapsed}
            handleDrawerClose={props.handleDrawerClose}
            handleDrawerOpen={props.handleDrawerOpen}
            popupHandler={(component, logo) =>
              props.popupHandler(component, logo)
            }
            closePopup={props.closePopup}
          />
        </MobileView>
      </Col>

      <div style={{ display: "flex", alignItems: "center" }}>
        {loggedIn ? (
          <Col xl={8} xs={4}>
            <Notification />
          </Col>
        ) : null}

        {!loggedIn ? (
          <Col xl={24}>
            <div
              className="auth-container"
              style={{
                justifyContent: "flex-end",
              }}
            >
              <button
                onClick={() => {
                  resetModals();
                  showSignin(true);
                }}
                className="auth-sigin"
              >
                {i18n.t("text.SignIn")}
              </button>
              <button
                onClick={() => {
                  resetModals();
                  showSignup(true);
                }}
                className="auth-signup"
              >
                {i18n.t("text.SignUp")}
              </button>
            </div>
          </Col>
        ) : null}

        {props.history.location.pathname === "/director" ? (
          <QuestionMark
            onClick={() => setModalVisible(true)}
            color={theme === "dark" ? "#EADCFF" : "#3B3B3B"}
          />
        ) : null}
      </div>

      <RealTimeTipsModal
        showTooltips={() => setTooltip({ quickLiveTooltip: true })}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        quickGuide={quickGuide}
        setQuickGuide={setQuickGuide}
      />

      {signin && (
        <SignInModal
          signin={signin}
          showSignin={showSignin}
          showSignup={showSignup}
          showForgetPassword={showForgetPassword}
          resetModals={resetModals}
          forgetPassword={forgetPassword}
        />
      )}
      {signup && (
        <Signup
          signup={signup}
          showSignup={showSignup}
          resetModals={resetModals}
        />
      )}
      {forgetPassword && (
        <ForgotPassword
          forgetPassword={forgetPassword}
          showForgetPassword={showForgetPassword}
          resetModals={resetModals}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(HeaderContent);
