import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  MSDSelect,
  MobileSelect,
} from "@pmviulive/msd-reactjs";
import { MobileView, BrowserView, isMobile } from "react-device-detect";
import i18n from "../../../../config/i18n";

const SignupForm = (props) => {
  // let locations = [...props.locations];
  const [location, setLocation] = useState(null);
  return (
    <Form
      onSubmit={(e) => props.sendCodehandler(e)}
      style={{ overflow: "auto" }}
      className="form-container"
    >
      <Form.Item>
        <Input
          className="auth-input"
          placeholder={i18n.t("text.fullName")}
          type="text"
          style={
            isMobile
              ? {
                  height: "32px",
                  padding: "16px",
                  marginTop: "16px",
                }
              : null
          }
          onChange={(e) => props.onChangeHandler("name", e.target.value)}
        />
      </Form.Item>
      {/* {props.locations ? (
        <React.Fragment>
          <BrowserView>
            <Form.Item>
              {props.locationStatus === "Denied" ? (
                <div className="locationDeniedPermission">
                  <div>{i18n.t("errors.locationPermissionDenied")}</div>
                  <div>{i18n.t("errors.locationPermissionDeniedDetail")}</div>
                </div>
              ) : null}
              {props.locationStatus && sessionStorage.getItem("myLocation") ? (
                <MSDSelect
                  options={locations}
                  idField="id"
                  titleField="name"
                  defaultValue={
                    location ? location : sessionStorage.getItem("myLocation")
                  }
                  placeholder={i18n.t("text.selectCountry")}
                  onChange={(e) => {
                    setLocation(e);
                    props.onChangeHandler("citizenshipCountryId", e);
                  }}
                />
              ) : null}
            </Form.Item>
          </BrowserView>
          <MobileView>
            <Form.Item>
              {props.locationStatus === "Denied" ? (
                <div className="locationDeniedPermission">
                  <div>{i18n.t("errors.locationPermissionDenied")}</div>
                  <div>{i18n.t("errors.locationPermissionDeniedDetail")}</div>
                </div>
              ) : null}
              {props.locationStatus && sessionStorage.getItem("myLocation") ? (
                <MobileSelect
                  options={locations}
                  defaultValue={
                    location ? location : sessionStorage.getItem("myLocation")
                  }
                  idField="id"
                  titleField="name"
                  onChange={(e) => {
                    setLocation(e);
                    props.onChangeHandler("citizenshipCountryId", e);
                  }}
                />
              ) : null}
            </Form.Item>
          </MobileView>
        </React.Fragment>
      ) : null} */}
      <Form.Item>
        <Input
          className="auth-input"
          placeholder={i18n.t("text.emailAdress")}
          type="text"
          onChange={(e) => props.onChangeHandler("email", e.target.value)}
          style={
            isMobile
              ? {
                  height: "32px",
                  padding: "16px",
                  marginTop: "16px",
                }
              : null
          }
        />
      </Form.Item>
      <Form.Item>
        <Input
          className="auth-input"
          placeholder={`${i18n.t("text.password")}*`}
          type="password"
          autoComplete="off"
          onChange={(e) => props.onChangeHandler("password", e.target.value)}
          style={
            isMobile
              ? {
                  height: "32px",
                  padding: "16px",
                  marginTop: "16px",
                }
              : null
          }
        />
      </Form.Item>
      <Form.Item>
        <Input
          className="auth-input"
          placeholder={`${i18n.t("text.confirmPassword")}*`}
          type="password"
          style={
            isMobile
              ? {
                  height: "32px",
                  padding: "16px",
                  marginTop: "16px",
                }
              : null
          }
          onChange={(e) =>
            props.onChangeHandler("confirmPassword", e.target.value)
          }
        />
      </Form.Item>
      {/* <Form.Item>
        <Input
          defaultValue={props.promotionCode}
          placeholder={i18n.t("text.promotionCode")}
          type="text"
          onChange={(e) =>
            props.onChangeHandler("promotionCode", e.target.value)
          }
        />
      </Form.Item> */}
      <Form.Item>
        <div className="registerBtn">
          <Button
            htmlType="submit"
            onClick={(e) => (props.sendCodehandler(e) ? props.fireTrems() : {})}
            // style={{ width: "100%" }}
            style={
              isMobile
                ? {
                    height: "40px",
                    // padding: "16px",
                    fontSize: "16px",
                    marginTop: "24px",
                    width: "100%",
                  }
                : {
                    width: "100%",
                  }
            }
          >
            {i18n.t("text.SignUp")}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default SignupForm;
