import React, { Component } from "react";
import { connect } from "react-redux";
import Countdown from "react-countdown-now";
import ReactCodeInput from "react-verification-code-input";
import { Form, Input, Button, Spinner, Modal } from "@pmviulive/msd-reactjs";
import { checkValidation, changePasswordValidation } from "./validityCheck";
import {
  forgotPasswordSendCode,
  forgotPasswordCheckCode,
  forgotPasswordChangePassword,
} from "@pmviulive/vume-js";
import i18n from "../../../../config/i18n";
import { logEvent } from "../../../../config/analytics";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";
import "../signup/signup.scss";
import "./forgotPassword.scss";
import { ArrowClockWise } from "../../../../config/icons";
import { isMobile } from "react-device-detect";

class ForgotPassword extends Component {
  state = {
    code: null,
    email: null,
    password: null,
    confirmPassword: null,
    step: 0,
    isCountdownFinish: false,
    countdown: null,
    count: Date.now() + 10 * 60000,
    joinTimes: 0,
    completedCode: false,
  };

  codeRef = React.createRef();
  countdown = () => {
    const countdown = setInterval(() => {
      if (this.state.count > 0) {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      } else {
        clearInterval(countdown);
        this.setState({ isCountdownFinish: true });
      }
    }, 1000);
    this.setState({ countdown });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.countdown);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step === 1 && prevState.step === 0) {
      this.countdown();
    }

    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.state.step === 0
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.emailNotExist"),
      });
    }

    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.state.step === 0
    ) {
      this.setState({ step: 1 });
    }

    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.state.step === 1 &&
      this.props.error.type === "error.validation"
    ) {
      this.setState({ joinTimes: this.state.joinTimes + 1 }, () => {
        if (this.state.joinTimes > 10) {
          throwNotification({
            type: "error",
            message: i18n.t("errors.blockedByOtp"),
          });
          this.setState({ step: 0, joinTimes: 0 });
        } else {
          throwNotification({
            type: "error",
            message: i18n.t("errors.verificationCode"),
          });
        }
      });
    }

    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.state.step === 1
    ) {
      if (this.props.response === "checkCode") {
        this.setState({ step: 2 });
      }
    }

    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.state.step === 2
    ) {
      logEvent("FORGET_PASSWORD");
      throwNotification({
        type: "success",
        message: i18n.t("success.changePassword"),
      });
      this.props.showForgetPassword(false);
      this.resetModal();
    }

    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.props.error.statusCode === 400
    ) {
      if (
        this.props.error.reasons &&
        this.props.error.reasons.some(
          (reason) => reason.objectName === "otp"
        ) &&
        this.state.step === 2
      ) {
        throwNotification({
          type: "error",
          message: i18n.t("info.otpExpired"),
        });
      }
    }
  }

  onChangeHandler = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  emailHandler = (e) => {
    e.preventDefault();
    const valid = checkValidation(this.state.email);
    if (valid) {
      this.props.sendCode(this.state.email, this.props.server);
    }
  };

  changePasswordSubmit = (e) => {
    e.preventDefault();
    const valid = changePasswordValidation(
      this.state.password,
      this.state.confirmPassword
    );
    if (valid) {
      this.props.changePassword(
        this.state.email,
        this.state.code,
        this.state.confirmPassword,
        this.props.server
      );
      this.props.showForgetPassword(false);
      throwNotification({
        type: "success",
        message: i18n.t("success.changePassword"),
      });
      this.resetModal();
    }
  };

  checkCodeHandler = (e) => {
    e.preventDefault();
    if (this.state.code?.length !== 6) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.verificationCode"),
      });
      return;
    }
    if (this.state.code?.length === 6) {
      this.props.checkCode(
        this.state.email,
        this.state.code,
        this.props.server
      );
    }
  };

  renderer = ({ minutes, seconds }) => {
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };

  resetModal = () => {
    this.setState({
      code: null,
      email: "",
      password: "",
      confirmPassword: "",
      step: 0,
      isCountdownFinish: false,
      count: Date.now() + 10 * 60000,
      joinTimes: 0,
      completedCode: false,
    });
    clearInterval(this.state.countdown);
  };

  clear() {
    this.codeRef.current.__clearvalues__();
  }
  render() {
    const containerClass =
      this.props.theme === "dark"
        ? "verificationContainer dark"
        : "verificationCard";
    return (
      <Modal
        centered
        className={`forgotPasswordForm ${
          this.props.theme === "dark" ? "dark-modal" : "light-modal"
        } ${isMobile ? "forgotPasswordForm-mobile" : ""}`}
        visible={this.props.forgetPassword}
        onCancel={() => {
          this.resetModal();
          this.props.resetModals();
          this.props.showForgetPassword
            ? this.props.showForgetPassword(false)
            : this.props.onCancel();
        }}
        width={isMobile ? "312px" : "472px"}
      >
        {this.props.loading && <Spinner className="absoluteLoader" />}
        <h1
          className="form-title"
          style={
            isMobile
              ? {
                  fontSize: "18px",
                  fontWeight: "600",
                  marginBottom: "20px",
                  textAlign: "center",
                  lineHeight: "20px",
                }
              : {
                  textAlign: "center",
                }
          }
        >
          {i18n.t("text.resetPassword")}
        </h1>
        {/* ====FIRST STEP==== */}
        {this.state.step === 0 ? (
          <Form onSubmit={(e) => this.emailHandler(e)}>
            <Form.Item>
              <Input
                type="text"
                placeholder={i18n.t("text.emailAdress")}
                onChange={(e) => this.onChangeHandler("email", e)}
                className="verify-input"
                style={
                  isMobile
                    ? {
                        height: "32px",
                        marginBottom: "0px",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }
                    : {}
                }
              />
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: "center" }}>
                <Button
                  htmlType="submit"
                  className="transparentBtn blockBtn"
                  onClick={(e) => this.emailHandler(e)}
                  style={
                    isMobile
                      ? {
                          fontSize: "16px",
                          lineHeight: "20px",
                          letterSpacing: "0.5px",
                        }
                      : {}
                  }
                >
                  {i18n.t("text.resetPassword")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : this.state.step === 1 ? (
          isMobile ? (
            <div className={containerClass}>
              <div className="contentWrapper">
                <div className="formSection">
                  <div className="contentWrapper">
                    <div className="inputGroup">
                      <div className="inputWrapper">
                        <label
                          htmlFor="verificationCode"
                          className="visually-hidden"
                        >
                          Verification Code
                        </label>
                        <ReactCodeInput
                          type="number"
                          onChange={(e) => this.setState({ code: e })}
                          onComplete={(e) => this.setState({ code: e })}
                          id="verificationCode"
                          className="verificationInput-Mobile"
                          required
                          ref={this.codeRef}
                        />
                        <button
                          type="button"
                          className="iconButton"
                          style={
                            isMobile
                              ? {
                                  marginTop: "0px",
                                }
                              : {}
                          }
                          aria-label="Resend verification code"
                          onClick={() => {
                            this.props.sendCode(
                              {
                                email: this.state.email,
                              },
                              this.props.server
                            );
                            this.setState({
                              count: Date.now() + 10 * 60000,
                              isCountdownFinish: false,
                            });
                            this.clear();
                          }}
                        >
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/640a90e8e2ad55476f0944ce84d72f7c613438d60693f3b3d2c8bb216f9b6641?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398"
                            className="icon"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                    <div className="timerSection">
                      <span className="timerText">
                        {this.state.isCountdownFinish
                          ? "Verification code has expired. Please click the retry button"
                          : "Verification code will expire in:"}
                      </span>
                      {!this.state.isCountdownFinish && (
                        <span
                          className="timerValue"
                          role="timer"
                          aria-label="Time remaining"
                        >
                          <Countdown
                            date={this.state.count}
                            renderer={this.renderer}
                            onComplete={() =>
                              this.setState({ isCountdownFinish: true })
                            }
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="confirmButtonWrapper">
                    <button
                      type="submit"
                      className="confirmButton"
                      onClick={(e) => this.checkCodeHandler(e)}
                      style={
                        isMobile
                          ? {
                              fontSize: "16px",
                              marginTop: "0px",
                            }
                          : {}
                      }
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // ====SECOND STEP====
            <div className="forgotPasswordVerification">
              <div className="count-down" style={{ textAlign: "center" }}>
                {this.state.isCountdownFinish ? (
                  <span
                    className="count-info"
                    style={{
                      marginRight: 0,
                    }}
                  >
                    {i18n.t("info.otpExpired")} Please click below to{" "}
                    {i18n.t("info.resendTheVerificationCode")}
                  </span>
                ) : (
                  <span className="count-info">
                    {i18n.t("info.verificationExpire")}
                  </span>
                )}
                {!this.state.isCountdownFinish && (
                  <Countdown
                    date={this.state.count}
                    renderer={this.renderer}
                    onComplete={() =>
                      this.setState({ isCountdownFinish: true })
                    }
                  />
                )}
              </div>

              <div className="verifycode-container">
                {!this.state.isCountdownFinish && (
                  <ReactCodeInput
                    ref={this.codeRef}
                    className={`verificationInput ${
                      this.props.error ? "codeError" : ""
                    }`}
                    onChange={(e) => this.setState({ code: e })}
                    onComplete={(e) => this.setState({ code: e })}
                  />
                )}
                <span
                  className="resend"
                  onClick={() => {
                    this.setState({
                      isCountdownFinish: false,
                      count: Date.now() + 10 * 60000,
                    });
                    this.clear();
                    if (!this.state.email) return;
                    this.props.sendCode(this.state.email, this.props.server);
                  }}
                >
                  <ArrowClockWise />
                </span>
              </div>
              <button
                // disabled={this.state.code?.length !== 6}
                onClick={(e) => this.checkCodeHandler(e)}
              >
                {i18n.t("text.confirm")}
              </button>
            </div>
          )
        ) : this.state.step === 2 ? (
          // ====THIRD STEP====
          <Form onSubmit={(e) => this.changePasswordSubmit(e)}>
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                marginBottom: 5,
              }}
            ></div>

            <Form.Item>
              <Input
                type="password"
                autoComplete="off"
                onChange={(e) => this.onChangeHandler("password", e)}
                placeholder={i18n.t("text.newPassword")}
                className="verify-input"
                style={
                  isMobile
                    ? {
                        height: "32px",
                      }
                    : {}
                }
              />
            </Form.Item>
            <Form.Item>
              <Input
                type="password"
                autoComplete="off"
                onChange={(e) => this.onChangeHandler("confirmPassword", e)}
                placeholder={'Confirm New Password'}
                className="verify-input"
                style={
                  isMobile
                    ? {
                        height: "32px",
                      }
                    : {}
                }
              />
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: "center" }}>
                <Button
                  htmlType="submit"
                  className="transparentBtn blockBtn"
                  onClick={(e) => this.changePasswordSubmit(e)}
                >
                  {i18n.t("text.resetPassword")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.auth.response,
    loading: state.auth.loading,
    error: state.auth.error,
    server: state.local.server,
    theme: state.local.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (email, server) =>
      dispatch(forgotPasswordSendCode(email, server)),
    checkCode: (email, code, server) =>
      dispatch(forgotPasswordCheckCode(email, code, server)),
    changePassword: (email, code, newPassword, server) =>
      dispatch(forgotPasswordChangePassword(email, code, newPassword, server)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
