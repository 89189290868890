import React, { Component } from "react";
import { AvatarPic } from "@pmviulive/vume-reactjs";
import { Button } from "@pmviulive/msd-reactjs";
import { getMyPlan, getMyProfile } from "@pmviulive/vume-js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "./components/menu";
import Login from "../../modules/auth/signin/signin";
import i18n from "../../../config/i18n";
import InviteFriends from "../../sharedComponents/inviteFriends/inviteFriends";
import Favorites from "../../modules/setting/components/favorites/favorites";
import {
  saveToken,
  saveTheme,
  saveFavoriteCategories,
} from "../../../config/store/localRedux/actions";
import { logEvent } from "../../../config/analytics";
import { throwModal } from "../../sharedComponents/errors/throwError/throwError";

import "./sidebar.scss";

class Sidebar extends Component {
  state = {
    avatar: null,
    userInfo: null,
    manualLogout: false,
  };

  updateUserInfo = (identity) => {
    let userInfo;
    if (identity) {
      userInfo = identity;
    } else {
      userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    }
    this.setState({
      userInfo: userInfo,
      avatar:
        userInfo && userInfo.avatar
          ? userInfo.avatar.url
          : process.env.PUBLIC_URL + "/svg/user-without-image.svg",
    });
  };

  componentDidMount() {
    this.updateUserInfo();
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token");
    if (token) {
      this.props.getMyPlan(token, this.props.server);
      this.props.getMyProfile(
        token,
        localStorage.getItem("userId"),
        this.props.server
      );
    }


    console.log('window.innerHeight', window.innerHeight)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userPlanResponse &&
      this.props.userPlanResponse !== prevProps.userPlanResponse
    ) {
      this.props.getMyPlan(this.props.auth.token, this.props.server);
    }
    if (
      this.props.profileChanged &&
      this.props.profileChanged !== prevProps.profileChanged
    ) {
      if (this.props.profileChanged.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify(this.props.profileChanged.data)
        );
      } else {
        localStorage.setItem("user", JSON.stringify(this.props.profileChanged));
      }
      this.updateUserInfo();
    }
    if (
      this.props.myProfileResponse &&
      this.props.myProfileResponse !== prevProps.myProfileResponse
    ) {
      const theme =
        this.props.myProfileResponse.user.userSetting?.theme.toLowerCase();
      localStorage.setItem(
        "user",
        JSON.stringify(this.props.myProfileResponse.user.identity)

      );
      localStorage.setItem(
          "favoriteCategory",
          JSON.stringify(this.props.myProfileResponse.user.userSetting.favoriteCategories)

      );
      localStorage.setItem("theme", theme);
      this.props.saveTheme(theme);
      this.updateUserInfo();
      if (
        !this.props.myProfileResponse.user?.userSetting?.favoriteCategories &&
        !localStorage.getItem("favoriteCategory")
      ) {
        this.props.popupHandler(<Favorites popup={true} />);
      } else if (
        this.props.myProfileResponse.user?.userSetting?.favoriteCategories
      ) {
        this.props.saveFavoriteCategories(
          this.props.myProfileResponse.user.userSetting.favoriteCategories
        );
      }
    }
    if (this.props.token && this.props.token !== prevProps.token) {
      this.props.getMyProfile(
        this.props.token,
        localStorage.getItem("userId"),
        this.props.server
      );
    }
    if (this.props.myPlan && this.props.myPlan !== prevProps.myPlan) {
      localStorage.setItem("myPlan", JSON.stringify(this.props.myPlan.plan));
    }
    if (this.props.auth && this.props.auth !== prevProps.auth) {
      const isExistEvent = this.props.auth.permissions.includes(
        "ROLE_VUME_SERVICE_EVENT"
      );
      if (!isExistEvent)
        return throwModal("error", {
          title: "Warning",
          content:
            "You are not authorized to use the application. Please contact our support team.",
          okText: i18n.t("text.ok"),
        });

      this.props.getMyPlan(this.props.auth.token, this.props.server);

      const expirationDate = this.props.auth.serverDateTime;
      localStorage.setItem("user", JSON.stringify(this.props.auth.identity));
      if (this.props.rememberLogin) {
        localStorage.setItem("token", this.props.auth.token);
      } else {
        sessionStorage.setItem("token", this.props.auth.token);
      }
      localStorage.setItem("userId", this.props.auth.userId);
      localStorage.setItem("expirationDate", expirationDate);
      this.props.saveToken();
      this.updateUserInfo(this.props.auth.identity);
      if (!this.state.manualLogout) {
        this.props.history.push(this.props.location.pathname);
      }
      this.setState({ manualLogout: false });
    }
    if (this.props.logout && this.props.logout !== prevProps.logout) {
      logEvent(`LOGOUT`);
      if (this.props.logout === 200) {
        this.setState({
          avatar: process.env.PUBLIC_URL + "/svg/user-without-image.svg",
          userInfo: null,
        });
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("myPlan");
        localStorage.removeItem("provider");
        localStorage.removeItem("recentEmojis");
        localStorage.removeItem("totalViews");
        localStorage.removeItem("favoriteCategory");
        localStorage.removeItem("adBanner");

        this.props.saveToken();
        this.props.saveFavoriteCategories([]);
        if (this.state.manualLogout) {
          this.props.history.push("/");
        } else {
          if (this.props.collapsed) {
            this.props.toggleClick();
          }
        }
      }
    }
  }

  handleLogout = async () => {
    await this.setState({ manualLogout: true });
    this.props.history.push("/logout");
  };

  render() {
    return (
        <div className={`ant-layout-sider-children ${!this.props.collapsed ? "flex-sider" : ""}`}>
          <div>
            {!this.props.collapsed ? (
              <React.Fragment>
                {/* {this.state.userInfo && localStorage.getItem("user") ? (
                  <img
                    className="avatarBackground"
                    src={
                      process.env.PUBLIC_URL +
                      `/images/${
                        this.props.theme === "dark"
                          ? "profile-bg-dark"
                          : "profile-bg"
                      }.png`
                    }
                    alt="avatarBackground"
                  />
                ) : null} */}
                {localStorage.getItem("user") ? (
                  <div style={{position: "relative", cursor: "pointer", marginBottom: "13px"}}>
                      <div>
                        <div
                          onClick={
                            this.state.userInfo
                              ? () => this.props.history.push("/profile")
                              : null
                          }
                        >
                          <AvatarPic
                            className={`menuAvatar ${
                              this.state.userInfo ? "loginnedAvatar" : ""
                            }`}
                            usernameClassName="avatarUserName"
                            src={this.state.avatar}
                            username={
                              this.state.userInfo ? this.state.userInfo.name : "Guest"
                            }
                            onError={(e) => {
                              console.log(":onerror");
                              e.target.onerror = null;
                              e.target.src =
                                process.env.PUBLIC_URL +
                                "/svg/user-without-image.svg";
                            }}
                          />
                        </div>

                        <span
                          className="vume-setting-btn settingBtn"
                          onClick={() => this.props.history.push("/setting")}
                        >
                          Settings
                        </span>
                      </div>
                  </div>
                  ) : null}

                {/* {!localStorage.getItem("user") && (
                  <Login
                    popupHandler={(component) => this.props.popupHandler(component)}
                  />
                )} */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={
                    this.state.userInfo
                      ? () => this.props.history.push("/profile")
                      : null
                  }
                >
                  <AvatarPic
                    className="menuAvatar"
                    src={this.state.avatar}
                    onError={(e) => {
                      console.log(":onerror");
                      e.target.onerror = null;
                      e.target.src =
                        process.env.PUBLIC_URL + "/svg/user-without-image.svg";
                    }}
                  />
                </div>
              </React.Fragment>
            )}

            <Menu
              mode="inline"
              collapsed={this.props.collapsed}
              toggleClick={() => this.props.toggleClick()}
              location={this.props.location}
              history={this.props.history}
              quickLiveTooltip={this.props.quickLiveTooltip}
              setTooltips={this.props.setTooltips}
            />
          </div>

          {!this.props.collapsed && (
            <div className="copyright">
              <div style={{ display: "flex", justifyContent: "center" , marginBottom:'10px'}}>
                <Button
                  type="link"
                  className="footerLinks"
                  onClick={() => {
                    logEvent("PRIVACY");
                    this.props.history.push("/privacy");
                  }}
                >
                  {i18n.t("text.privacy")}
                </Button>

                <Button
                  type="link"
                  className="footerLinks"
                  onClick={() => {
                    logEvent("TERM");
                    this.props.history.push("/terms");
                  }}
                >
                  {i18n.t("text.terms")}
                </Button>

                <a
                  className="footerLinks"
                  href="https://viulive.com/blog/"
                  target="blank"
                  onClick={() => logEvent("BLOG")}
                >
                  {i18n.t("text.blog")}
                </a>

                <Button
                  type="link"
                  className="footerLinks"
                  onClick={() => {
                    logEvent("FAQ");
                    this.props.history.push("/faq");
                  }}
                >
                  {i18n.t("text.faq")}
                </Button>
              </div>

              <div className="inviteFriendBtn">
                <Button
                  className="transparentBtn arrow"
                  style={{
                    width: "100%",
                    fontSize: "13px",
                    height: " 25px",
                    borderRadius:'6px'
                  }}
                  onClick={() => this.props.popupHandler(<InviteFriends />)}
                >
                  <i className="icon-invite-friends"></i>
                  {i18n.t("text.inviteFriends")}
                </Button>
              </div>
              <div className="socialBox">
                <div
                  style={{
                    padding: "5px 0",
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom:'15px' 
                  }}
                >
                  <a
                    href="https://www.facebook.com/Viuliveapp-106889638527748"
                    target="blank"
                    alt="facebook"
                  >
                    <img src='/fb.svg' alt='facebook' width='14px' height='14px'/>
                  </a>
                  <a
                    href="https://www.instagram.com/viulive.app/"
                    target="blank"
                    alt="instagram"
                  >
                    <img  src='/insta.svg'  alt='insta' width='14px' height='14px'/>
                  </a>
                  <a
                    href="https://twitter.com/Viuliveapp"
                    target="blank"
                    alt="twitter"
                  >
                    <img src='/twitter.svg' alt='twitter'  width='16px' height='13px'/>
                  </a>
                </div>
                <div style={{ display: "flex", justifyContent: "space-around", marginBottom:'20px'  }}>
                  <div className="downloadAppSidebar">
                    <a
                      href="https://apps.apple.com/tt/app/viulive/id1493243242?ign-mpt=uo%3D2"
                      target="blank"
                      onClick={() => logEvent("APPLESTORE")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/apple.svg"}
                        alt="apple store"
                      />
                    </a>
                  </div>
                  <div className="downloadAppSidebar">
                    <a
                      href={`https://play.google.com/store/apps/details?id=com.millennium.vumemobile&hl=en`}
                      download={`Viulive-${process.env.REACT_APP_LEVEL}`}
                      target="blank"
                      onClick={() => logEvent("GOOGLEPLAY")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/google.svg"
                        }
                        alt="googlePlay"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <a
                href="http://www.beyond-apps-group.com/"
                target="blank"
                onClick={() => logEvent("BEYONDAPPS")}
                className="copyrightText"
              >
                Copyright © 2020 ViuCom <br></br>
                <p style={{ fontSize: "10px" }}>Beyond Apps Group</p>
              </a>
            </div>
          )}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileChanged: state.profile.response,
    myProfileResponse: state.profile.user,
    auth: state.auth.user,
    logout: state.auth.logout,
    myPlan: state.userPlans.myPlan,
    userPlanResponse: state.userPlans.response,
    token: state.local.token,
    rememberLogin: state.local.rememberLogin,
    server: state.local.server,
    theme: state.local.theme,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMyPlan: (token, server) => dispatch(getMyPlan(token, server)),
    getMyProfile: (token, id, server) =>
      dispatch(getMyProfile(token, id, server)),
    saveToken: () => dispatch(saveToken()),
    saveTheme: (theme) => dispatch(saveTheme(theme)),
    saveFavoriteCategories: (categories) =>
      dispatch(saveFavoriteCategories(categories)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
