import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Drawer, Button } from "@pmviulive/msd-reactjs";
import { AvatarPic } from "@pmviulive/vume-reactjs";
import Login from "../../modules/auth/signin/signin";
import { getMyPlan, getMyProfile } from "@pmviulive/vume-js";
import InviteFriends from "../../sharedComponents/inviteFriends/inviteFriends";
import { MobileView } from "react-device-detect";
import i18n from "../../../config/i18n";
import {
  saveToken,
  saveTheme,
  saveFavoriteCategories,
} from "../../../config/store/localRedux/actions";
import { throwModal } from "../../sharedComponents/errors/throwError/throwError";
import Favorites from "../../modules/setting/components/favorites/favorites";

import "./sidebar";

class MobileSidebar extends Component {
  state = {
    avatar: null,
    userInfo: null,
    manualLogout: false,
  };

  updateUserInfo = (identity) => {
    let userInfo;
    if (identity) {
      userInfo = identity;
    } else {
      userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    }
    this.setState({
      userInfo: userInfo,
      avatar:
        userInfo && userInfo.avatar
          ? userInfo.avatar.url
          : process.env.PUBLIC_URL + "/svg/user-without-image.svg",
    });
  };

  componentDidMount() {
    this.updateUserInfo();
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token");
    if (token) {
      this.props.getMyPlan(token, this.props.server);
      this.props.getMyProfile(
        token,
        localStorage.getItem("userId"),
        this.props.server
      );
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.myProfileResponse &&
      this.props.myProfileResponse !== prevProps.myProfileResponse
    ) {
      const theme =
        this.props.myProfileResponse.user.userSetting?.theme.toLowerCase();
      localStorage.setItem(
        "user",
        JSON.stringify(this.props.myProfileResponse.user.identity)
      );
      localStorage.setItem("theme", theme);
      this.props.saveTheme(theme);
      this.updateUserInfo();
      if (
        !this.props.myProfileResponse.user?.userSetting.favoriteCategories &&
        !localStorage.getItem("favoriteCategory")
      ) {
        // this.props.popupHandler(<Favorites popup={true} />);
      } else if (
        this.props.myProfileResponse.user?.userSetting?.favoriteCategories
      ) {
        this.props.saveFavoriteCategories(
          this.props.myProfileResponse.user.userSetting.favoriteCategories
        );
      }
    }
    if (this.props.token && this.props.token !== prevProps.token) {
      this.props.getMyProfile(
        this.props.token,
        localStorage.getItem("userId"),
        this.props.server
      );
    }

    if (
      this.props.userPlanResponse &&
      this.props.userPlanResponse !== prevProps.userPlanResponse
    ) {
      this.props.getMyPlan(this.props.auth.token, this.props.server);
    }
    if (
      this.props.profileChanged &&
      this.props.profileChanged !== prevProps.profileChanged
    ) {
      if (this.props.profileChanged.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify(this.props.profileChanged.data)
        );
      } else {
        localStorage.setItem("user", JSON.stringify(this.props.profileChanged));
      }
      this.updateUserInfo();
    }
    if (this.props.myPlan && this.props.myPlan !== prevProps.myPlan) {
      localStorage.setItem("myPlan", JSON.stringify(this.props.myPlan.plan));
    }
    if (this.props.auth && this.props.auth !== prevProps.auth) {
      const isExistEvent = this.props.auth.permissions.includes(
        "ROLE_VUME_SERVICE_EVENT"
      );
      if (!isExistEvent)
        return throwModal("error", {
          title: "Warning",
          content:
            "You are not authorized to use the application. Please contact our support team.",
          okText: i18n.t("text.ok"),
        });
      this.props.getMyPlan(this.props.auth.token, this.props.server);

      const expirationDate = this.props.auth.serverDateTime;
      localStorage.setItem("user", JSON.stringify(this.props.auth.identity));
      if (this.props.rememberLogin) {
        localStorage.setItem("token", this.props.auth.token);
      } else {
        sessionStorage.setItem("token", this.props.auth.token);
      }
      localStorage.setItem("userId", this.props.auth.userId);
      localStorage.setItem("expirationDate", expirationDate);
      this.props.saveToken();

      this.updateUserInfo(this.props.auth.identity);
      if (!this.state.manualLogout) {
        this.props.history.push(this.props.location.pathname);
      }

      this.setState({
        manualLogout: false,
      });
      this.props.handleDrawerClose();
    }
    if (this.props.logout && this.props.logout !== prevProps.logout) {
      if (this.props.logout === 200) {
        this.setState({
          avatar: process.env.PUBLIC_URL + "/svg/user-without-image.svg",
          userInfo: null,
        });
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("myPlan");
        localStorage.removeItem("provider");
        localStorage.removeItem("totalViews");
        localStorage.removeItem("recentEmojis");
        localStorage.removeItem("favoriteCategory");
        localStorage.removeItem("adBanner");

        this.props.saveToken();
        this.props.saveFavoriteCategories([]);

        if (this.state.manualLogout) {
          this.props.history.push("/");
        } else {
          if (!this.props.collapsed) {
            // this.props.handleDrawerOpen();
          }
        }
      }
    }
  }

  handleLogout = async () => {
    await this.setState({ manualLogout: true });
    this.props.history.push("/logout");
  };

  handleFooterLink = (link) => {
    this.props.history.push(link);
    this.props.handleDrawerClose();
  };

  render() {
    return (
      <div className="mobileSidebar">
        <Drawer
          placement="right"
          onClose={this.props.handleDrawerClose}
          // onClick={
          //     props.collapsed ? props.handleDrawerOpen : props.handleDrawerClose
          //   }
          visible={this.props.visible}
          width="100%"
          className={`${this.props.theme === "dark" ? "darkDrawer" : ""}`}
        >
          <div className={`theme theme--${this.props.theme || "light"}`}>
            <div
              style={{ textAlign: "center" }}
              onClick={
                this.state.userInfo
                  ? () => this.handleFooterLink("/profile")
                  : null
              }
            >
              {localStorage.getItem("user") ? (
                <MobileView>
                  <img
                    className="avatarBackground mobileSidebar"
                    src={
                      process.env.PUBLIC_URL +
                      `/images/${
                        this.props.theme === "dark"
                          ? "profile-bg-dark"
                          : "profile-bg"
                      }.png`
                    }
                    alt="avatarBackground"
                  />
                </MobileView>
              ) : null}
              {this.props.token ? (
                <div onClick={() => this.props.history.push("/profile")}>
                  <AvatarPic
                    className={`menuAvatar ${
                      localStorage.getItem("user") ? "mobileSidebar" : ""
                    }`}
                    usernameClassName="avatarUserName"
                    src={this.state.avatar}
                    username={
                      this.state.userInfo ? this.state.userInfo.name : "Guest"
                    }
                    onError={(e) => {
                      console.log(":onerror");
                      e.target.onerror = null;
                      e.target.src =
                        process.env.PUBLIC_URL + "/svg/user-without-image.svg";
                    }}
                  />
                </div>
              ) : null}
            </div>
            {this.props.token && (
              <div className="textCenter" style={{ marginBottom: "20px" }}>
                <span
                  className="settingBtn"
                  onClick={() => this.handleFooterLink("/setting")}
                >
                  <i className="icon-menu-settings"></i>
                  Settings
                </span>
              </div>
            )}
            {!localStorage.getItem("user") && (
              <Login
                popupHandler={(component) => this.props.popupHandler(component)}
              />
            )}

            <div className="copyright">
              <div className="socialBox">
                <div
                  style={{
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <a
                    href="https://www.facebook.com/Viuliveapp-106889638527748"
                    target="blank"
                    alt="facebook"
                  >
                    <i className="icon-fb" style={{ fontSize: 17 }}></i>
                  </a>
                  <a
                    href="https://www.instagram.com/viulive.app/"
                    target="blank"
                    alt="instagram"
                  >
                    <i className="icon-ig" style={{ fontSize: 17 }}></i>
                  </a>
                  <a
                    href="https://twitter.com/Viuliveapp"
                    target="blank"
                    alt="twitter"
                  >
                    <i className="icon-twitter1" style={{ fontSize: 15 }}></i>
                  </a>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div className="downloadAppSidebar">
                    <a
                      href="https://apps.apple.com/tt/app/viulive/id1493243242?ign-mpt=uo%3D2"
                      target="blank"
                    >
                      <img
                        src={'/apple.svg'}
                        alt="apple store"
                      />
                    </a>
                  </div>
                  <div className="downloadAppSidebar">
                    <a
                      href={`https://play.google.com/store/apps/details?id=com.millennium.vumemobile&hl=en`}
                      download={`Viulive-${process.env.REACT_APP_LEVEL}`}
                      target="blank"
                    >
                      <img
                        src={'/google.svg'}
                        alt="googlePlay"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="inviteFriendBtn">
                <Button
                  className="transparentBtn arrow"
                  style={{
                    width: "100%",
                    fontSize: "13px",
                    height: " 25px",
                  }}
                  onClick={() => this.props.popupHandler(<InviteFriends />)}
                >
                  <i className="icon-invite-friends"></i>
                  {i18n.t("text.inviteFriends")}
                  <i
                    className="icon-cam-next"
                    style={{
                      fontSize: "10px",
                      position: "relative",
                      right: "-10px",
                      top: "0px",
                    }}
                  ></i>
                </Button>
              </div>
              <a
                href="http://www.beyond-apps-group.com/"
                target="blank"
                className="copyrightText"
              >
                Copyright © 2020 ViuCom <br></br>
                <b style={{ fontSize: "12px" }}>Beyond Apps Group</b>
              </a>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileChanged: state.profile.response,
    auth: state.auth.user,
    logout: state.auth.logout,
    myPlan: state.userPlans.myPlan,
    userPlanResponse: state.userPlans.response,
    token: state.local.token,
    rememberLogin: state.local.rememberLogin,
    server: state.local.server,
    theme: state.local.theme,
    myProfileResponse: state.profile.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMyPlan: (token, server) => dispatch(getMyPlan(token, server)),
    getMyProfile: (token, id, server) =>
      dispatch(getMyProfile(token, id, server)),
    saveToken: () => dispatch(saveToken()),
    saveTheme: (theme) => dispatch(saveTheme(theme)),
    saveFavoriteCategories: (categories) =>
      dispatch(saveFavoriteCategories(categories)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileSidebar)
);
