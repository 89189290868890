import React from "react";
import { connect } from "react-redux";
import { checkValidation } from "./validityCheck";
import { Spinner, Row, Icon } from "@pmviulive/msd-reactjs";
import { userLogin, userSocialLogin } from "@pmviulive/vume-js";
import LoginForm from "./loginForm";
import i18n from "../../../../config/i18n";

import {
  googleLoginCode,
  facebookLoginCode,
} from "../../../../config/serverConfig";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import ForgotPassword from "../forgotPassword/forgotPassword";
import { webUrl } from "../../../../config/serverConfig";
import { logEvent } from "../../../../config/analytics";
import {
  throwNotification,
  throwModal,
} from "../../../sharedComponents/errors/throwError/throwError";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";

import "./signin.scss";

class Signin extends React.Component {
  submitLogin = (e) => {
    const validity = checkValidation(
      e.username && e.username.trim(),
      e.password
    );
    if (validity) {
      this.props.login(e.username.trim(), e.password, this.props.server);
    }
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.auth &&
      (!this.props.response || this.props.response === 200) &&
      !this.props.loading
    ) {
      logEvent(`LOGIN_VIULIVE`);
    }
    if (this.props.auth && this.props.response && !this.props.loading) {
      this.props.response.provider &&
        !this.props.response.newMember &&
        logEvent(`LOGIN_${this.props.response.provider}`);
      this.props.response.provider &&
        this.props.response.newMember &&
        logEvent(`REGISTER_${this.props.response.provider}`);
    }

    if (
      this.props.error &&
      (this.props.error.statusCode === 401 ||
        this.props.error.statusCode === 400) &&
      this.props.error !== prevProps.error
    ) {
      localStorage.removeItem("provider");
      if (
        this.props.error.statusCode === 400 &&
        this.props.error.reasons[0].objectName ===
          "userRegisterByGlobalProvider"
      ) {
        throwNotification({
          type: "error",
          message: i18n.t("info.loginByForm"),
        });
      }
      if (
        this.props.error.type === "error.security.unauthorized.badCredentials"
      ) {
        throwNotification({
          type: "error",
          message: i18n.t("errors.authFailed"),
        });
      }
      if (this.props.error.type === "error.security.unauthorized.locked") {
        throwModal("confirm", {
          title: i18n.t("info.accountLocked"),
          content: i18n.t("info.accountLockedDetail"),
          okText: i18n.t("text.recover"),
          onOk() {
            prevProps.popupHandler(<ForgotPassword />);
          },
        });
      }
    }
    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.props.response.generatedPassword
    ) {
      localStorage.setItem("provider", this.props.response.provider);
      this.props.login(
        this.props.response.email,
        this.props.response.generatedPassword,
        this.props.server
      );
    }
  }
   responseGoogle = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    const email = decoded.email;
    const idToken = credentialResponse.credential;
    const name = decoded.name;
    this.callSocialLogin(email, idToken, "GOOGLE");

  };
  responseFacebook = (response) => {
    this.callSocialLogin(response.email, response.accessToken, "FACEBOOK");
  };

  callSocialLogin = (email, idToken, provider, name) => {
    const socialLoginData = {
      email: email,
      idToken: idToken,
      provider: provider,
      name: name,
    };
    this.props.socialLogin(socialLoginData, this.props.server);
  };
  appleLogin = async () => {
    window.AppleID.auth.init({
      clientId: "app.millennium.vumemobile",
      scope: "email name",
      redirectURI: webUrl,
      usePopup: true, //or false defaults to false
    });

    try {
      const data = await window.AppleID.auth.signIn();
      if (data.user) {
        const fullName =
          data.user.name.firstName + " " + data.user.name.lastName;
        this.callSocialLogin(
          data.user.email,
          data.authorization.id_token,
          "APPLE",
          fullName
        );
      } else {
        this.callSocialLogin(null, data.authorization.id_token, "APPLE");
      }
    } catch (error) {}
  };
  render() {
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading.login ? (
          <Spinner className="absoluteLoader" />
        ) : null}
        <div className="socialLogin">
          <Row>
            <div className="googleClass">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
              this.responseGoogle(credentialResponse);
              }}
              onError={() => {
              console.log("Login Failed");
            }}
            width={173}
          />
          </div>
          </Row>
          <Row>
            <FacebookLogin
              appId={facebookLoginCode}
              autoLoad={false}
              callback={this.responseFacebook}
              redirectUri={webUrl}
              fields="email"
              disableMobileRedirect={true}
              render={(renderProps) => (
                <button className="fbClass" onClick={renderProps.onClick}>
                  <Icon type="facebook" theme="filled" className="socialIcon" />
                  <span>{i18n.t("text.fbSignIn")}</span>
                </button>
              )}
            />
          </Row>
          <Row>
            <button className="appleClass" onClick={this.appleLogin}>
              <Icon type="apple" theme="filled" className="socialIcon" />
              <span>{i18n.t("text.appleSignIn")}</span>
            </button>
          </Row>
        </div>
        <LoginForm
          onSubmit={(e) => this.submitLogin(e)}
          popupHandler={(component) => this.props.popupHandler(component)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.user,
    error: state.auth.error,
    loading: state.auth.loading,
    response: state.auth.response,
    server: state.local.server,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, server) =>
      dispatch(userLogin(username, password, server)),
    socialLogin: (object, server) => dispatch(userSocialLogin(object, server)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
