import React, { Component } from "react";
import { connect } from "react-redux";
import {
  userRegisterSendCode,
  userRegisterRegister,
  userLogin,
  getMyPlan,
  setFreePremium,
} from "@pmviulive/vume-js";
import { withRouter } from "react-router-dom";
import { Spinner, Button, Checkbox } from "@pmviulive/msd-reactjs";
import { checkValidation } from "./validityCheck";
import SignupForm from "./signupForm";
import Countdown from "react-countdown-now";
import ReactCodeInput from "react-verification-code-input";
import i18n from "../../../../config/i18n";
import Terms from "../../termsAndConditions/termsAndConditions";
import { logEvent } from "../../../../config/analytics";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";
import { Modal } from "@pmviulive/msd-reactjs";
import { Link } from "react-router-dom";
import {
  ArrowClockWise,
  LogoMobileAuthDark,
  LogoMobileAuthWhite,
  LogoWebAuthDark,
  LogoWebAuthWhite,
} from "../../../../config/icons";
import "./signup.scss";
import "../forgotPassword/forgotPassword.scss";
import { isMobile } from "react-device-detect";
class Signup extends Component {
  state = {
    email: null,
    password: null,
    confirmPassword: null,
    identity: {
      avatar: {
        key: "userAvatar",
        status: "NOT_CHANGED",
        encoding: "NONE",
      },
      name: null,
      type: "PERSON",
      citizenshipCountryId:
        sessionStorage.getItem("myLocation") || "5c2ecd7e5adf253980c0cb5e",
      nationalityCountryId:
        sessionStorage.getItem("myLocation") || "5c2ecd7e5adf253980c0cb5e",
    },
    promotionCode:
      this.props.location.search && this.props.location.search.includes("pcode")
        ? this.props.location.search.split("=")[1]
        : null,
    otp: null,
    step: 0,
    termsApprove: false,
    locationStatus: "Pending",
    count: Date.now() + 5 * 60000,
    validity: false,
    joinTimes: 0,
    isCountdownFinish: false,
    disabled: false,
  };

  codeRef = React.createRef();
  countdown = () => {
    const countdown = setInterval(() => {
      if (this.state.count > 0) {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      } else {
        clearInterval(countdown);
        this.setState({ isCountdownFinish: true });
      }
    }, 1000);
    this.setState({ countdown });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.countdown);
  };

  componentDidMount() {
    navigator.geolocation.watchPosition(
      (res) => {
        this.setState({ locationStatus: "Approved" });
      },
      (err) => {
        if (err.message.includes("denied")) {
          this.setState({ locationStatus: "Denied" });
        }
      }
    );
  }

  onChangeHandler = (key, e) => {
    if (key === "name") {
      const newValue = {
        ...this.state.identity,
        [key]: e.trim(),
      };
      this.setState({
        identity: newValue,
      });
    } else if (key === "citizenshipCountryId") {
      const newValue = {
        ...this.state.identity,
        nationalityCountryId: e,
        [key]: e,
      };
      this.setState({
        identity: newValue,
      });
    } else {
      this.setState({
        [key]: e || (e.length ? e : null),
      });
    }
  };

  sendCodeHandler = (e) => {
    e.preventDefault();
    const validity = checkValidation(this.state);
    this.setState({ validity });
    if (validity) {
      this.toggleTerms();
    }
  };

  sendCode = () => {
    this.props.sendCode(
      {
        email: this.state.email.trim(),
        // promotionCode: this.state.promotionCode,
      },
      this.props.server
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.auth &&
      this.props.response === 200 &&
      !this.props.loading &&
      !prevProps.auth
    ) {
      logEvent(`REGISTER_VIULIVE`);
    }
    // if (this.state.step === 2 && prevState.step === 1) {
    if (this.state.step === 2 && prevState.step !== 2) {
      // this.countdown();
      this.setState({
        count: Date.now() + 5 * 60000,
        isCountdownFinish: false,
      });
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.props.error.statusCode === 428 &&
      this.props.error.type === "error.duplicateData"
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.duplicatedEmail"),
      });
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.props.error.statusCode === 400 &&
      this.props.error.type === "error.validation" &&
      this.props.error.reasons[0].field === "promotionCode"
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.promotionCode"),
      });
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.props.error.statusCode === 400 &&
      this.props.error.type === "error.validation" &&
      this.props.error.reasons[0].field === "email"
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("validations.validEmail"),
      });
    }
    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.state.step === 1
    ) {
      this.setState({ step: 2 });
      if (this.state.promotionCode) {
        sessionStorage.setItem("promotionCode", this.state.promotionCode);
      }
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.state.step === 2
    ) {
      this.setState({ joinTimes: this.state.joinTimes + 1 }, () => {
        if (this.state.joinTimes > 10) {
          throwNotification({
            type: "error",
            message: i18n.t("errors.blockedByOtp"),
          });
          this.setState({ step: 0, joinTimes: 0 });
        } else {
          throwNotification({
            type: "error",
            message: i18n.t("errors.verificationCode"),
          });
        }
      });
    }
    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.state.step === 2
    ) {
      if (this.props.response !== "sendCode") {
        this.props.login(
          this.state.email,
          this.state.confirmPassword,
          this.props.server
        );
      }
    }
    if (this.props.auth && this.props.auth !== prevProps.auth) {
      this.props.getMyPlan(this.props.auth.token, this.props.server);
    }
    if (this.props.myPlan && this.props.myPlan !== prevProps.myPlan) {
      if (sessionStorage.getItem("promotionCode")) {
        this.props.setFreePremium(
          this.props.auth.token,
          sessionStorage.getItem("promotionCode"),
          this.props.server
        );
        sessionStorage.removeItem("promotionCode");
      }
      // Close the modal and reset the step
      this.props.showSignup(false);
      this.setState({ step: 0 });
    }
  }

  renderer = ({ minutes, seconds }) => {
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  toggleTerms = () => {
    this.setState({ step: 1 });
  };
  checkCode = () => {
    if (this.state.otp?.length !== 6) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.verificationCode"),
      });
      return;
    }
    if (this.state.otp !== null) {
      const newState = {
        ...this.state,
        code: this.state.otp,
      };
      if (
        !newState.identity.citizenshipCountryId &&
        !newState.identity.nationalityCountryId
      ) {
        newState.identity.citizenshipCountryId = "5c2ecd7e5adf253980c0cb5e";
        newState.identity.nationalityCountryId = "5c2ecd7e5adf253980c0cb5e";
      }
      this.props.register(newState, this.props.server);
    }
  };

  clear() {
    this.codeRef.current.__clearvalues__();
  }
  render() {
    const containerClass =
      this.props.theme === "dark"
        ? "verificationContainer dark"
        : "verificationCard";
    return (
      <Modal
        centered
        visible={this.props.signup}
        onCancel={() => {
          this.props.showSignup(false);
          this.setState({ step: 0 });
          this.props.resetModals();
        }}
        footer={false}
        className={`auth-modal ${
          this.props.theme === "dark"
            ? "signup-modal-dark"
            : "signup-modal-light"
        } ${isMobile ? "auth-modal-mobile" : ""}`}
        width={isMobile ? "312px" : "472px"}
        padding="0"
      >
        {this.props.loading ? <Spinner className="absoluteLoader" /> : null}

        <h3
          className="form-title"
          style={
            isMobile
              ? {
                  fontSize: `${
                    this.state.step === 1 || this.state.step === 2
                      ? "18px"
                      : "20px"
                  }`,
                  fontWeight: "700",
                  marginBottom: "10px",
                  textAlign: `${this.state.step === 1 ? "left" : "center"}`,
                }
              : null
          }
        >
          {this.state.step === 0
            ? i18n.t("text.SignUp") + ' to'
            : this.state.step === 1
            ? `${i18n.t("text.termsAndCondition")} of use`
            : this.state.step === 2
            ? "Verify your identity"
            : ""}
            {" "}
          {this.state.step === 0 ? (
            isMobile ? (
              this.props.theme === "dark" ? (
                <LogoMobileAuthDark />
              ) : (
                <LogoMobileAuthWhite />
              )
            ) : this.props.theme === "dark" ? (
              <LogoWebAuthDark />
            ) : (
              <LogoWebAuthWhite />
            )
          ) : null}
        </h3>
        {this.state.step === 0 ? (
          <SignupForm
            promotionCode={this.state.promotionCode}
            sendCodehandler={this.sendCodeHandler}
            onChangeHandler={this.onChangeHandler}
            history={this.props.history}
            fireTrems={this.toggleTerms}
            validity={this.state.validity}
          />
        ) : this.state.step === 1 ? (
          <>
            <div>
              <Terms theme={this.props.theme} inRegister={true} />
            </div>
            <Checkbox
              onChange={() => this.setState({ disabled: !this.state.disabled })}
              checked={this.state.disabled}
              className="remember-me terms-agree"
            >
              I've read and agreed to the{" "}
              <Link to="/terms">
                <span style={{ textDecoration: "underline" }}>
                  {i18n.t("text.termsAndCondition")}
                </span>
              </Link>
            </Checkbox>
            {isMobile ? (
              <button
                type="submit"
                onClick={this.sendCode}
                disabled={!this.state.disabled}
                className="agreed-button"
                style={
                  !this.state.disabled
                    ? {
                        cursor: "not-allowed",
                        height: "40px",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }
                    : {
                        cursor: "pointer",
                        height: "40px",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }
                }
              >
                {i18n.t("text.submit")}
              </button>
            ) : (
              <button
                type="submit"
                onClick={this.sendCode}
                disabled={!this.state.disabled}
                className="agreed-button"
                style={
                  !this.state.disabled
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              >
                {i18n.t("text.submit")}
              </button>
            )}
          </>
        ) : this.state.step === 2 ? (
          isMobile ? (
            <div className={containerClass}>
              <div className="contentWrapper">
                <p
                  className="instructions"
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                  }}
                >
                  To ensure your account's security, please enter the 6-digit
                  verification code sent to your email address.
                </p>
                <div className="formSection">
                  <div className="contentWrapper">
                    <div className="inputGroup">
                      <div className="inputWrapper">
                        <label
                          htmlFor="verificationCode"
                          className="visually-hidden"
                        >
                          Verification Code
                        </label>
                        <ReactCodeInput
                          type="number"
                          id="verificationCode"
                          className="verificationInput-Mobile"
                          required
                          onComplete={(e) => this.setState({ otp: e })}
                          onChange={(e) => this.setState({ otp: e })}
                          ref={this.codeRef}
                        />
                        <button
                          type="button"
                          className="iconButton"
                          aria-label="Resend verification code"
                          onClick={() => {
                            this.props.sendCode(
                              {
                                email: this.state.email,
                              },
                              this.props.server
                            );
                            this.setState({
                              count: Date.now() + 5 * 60000,
                              isCountdownFinish: false,
                            });
                            this.clear();
                          }}
                        >
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/640a90e8e2ad55476f0944ce84d72f7c613438d60693f3b3d2c8bb216f9b6641?placeholderIfAbsent=true&apiKey=55bff993095d42eb8f72d4e2ada69398"
                            className="icon"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                    <div className="timerSection">
                      <span className="timerText">
                        {this.state.isCountdownFinish
                          ? "Verification code has expired. Please click the retry button"
                          : "Verification code will expire in:"}
                      </span>
                      {!this.state.isCountdownFinish && (
                        <span
                          className="timerValue"
                          role="timer"
                          aria-label="Time remaining"
                        >
                          <Countdown
                            date={this.state.count}
                            renderer={this.renderer}
                            onComplete={() =>
                              this.setState({ isCountdownFinish: true })
                            }
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="confirmButtonWrapper">
                    <button
                      type="submit"
                      className="confirmButton"
                      onClick={this.checkCode}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="forgotPasswordVerification">
              <div className="count-down" style={{ textAlign: "center" }}>
                {this.state.isCountdownFinish ? (
                  <span
                    className="count-info"
                    style={{
                      marginRight: 0,
                    }}
                  >
                    {i18n.t("info.otpExpired")} Please click below to{" "}
                    {i18n.t("info.resendTheVerificationCode")}
                  </span>
                ) : (
                  <span className="count-info">
                    {i18n.t("info.verificationExpire")}
                  </span>
                )}
                {!this.state.isCountdownFinish && (
                  <Countdown
                    date={this.state.count}
                    renderer={this.renderer}
                    onComplete={() =>
                      this.setState({ isCountdownFinish: true })
                    }
                  />
                )}
              </div>

              <div className="verifycode-container">
                {!this.state.isCountdownFinish && (
                  <ReactCodeInput
                    className={`verificationInput ${
                      this.props.error ? "codeError" : ""
                    }`}
                    onComplete={(e) => this.setState({ otp: e })}
                    onChange={(e) => this.setState({ otp: e })}
                    disabled={this.state.disableCode}
                    ref={this.codeRef}
                  />
                )}

                <div className="resend">
                  <span
                    onClick={() => {
                      this.props.sendCode(
                        {
                          email: this.state.email,
                        },
                        this.props.server
                      );
                      this.setState({
                        count: Date.now() + 5 * 60000,
                        isCountdownFinish: false,
                      });
                      this.clear();
                    }}
                  >
                    <ArrowClockWise />
                  </span>
                </div>
              </div>
              <button className="agreed-button" onClick={this.checkCode}>
                {i18n.t("text.confirm")}
              </button>
            </div>
          )
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    auth: state.auth.user,
    response: state.auth.response,
    myPlan: state.userPlans.myPlan,
    server: state.local.server,
    locations: state.local.locations,
    theme: state.local.theme,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (object, server) =>
      dispatch(userRegisterSendCode(object, server)),
    register: (object, server) =>
      dispatch(userRegisterRegister(object, server)),
    login: (username, password, server) =>
      dispatch(userLogin(username, password, server)),
    getMyPlan: (token, server) => dispatch(getMyPlan(token, server)),
    setFreePremium: (token, promotionCode, server) =>
      dispatch(setFreePremium(token, promotionCode, server)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
