import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { Menu } from "@pmviulive/msd-reactjs";
import { isMobile, isFirefox, isChrome } from "react-device-detect";

import i18n from "../../../../config/i18n";
import { logEvent } from "../../../../config/analytics";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";
import { DirectIconSec, DirectorIcon, QuickLiveIcon, AdvancedLiveIcon } from "../../../../config/icons";
import TooltipModal from "../../../modules/director/components/TooltipModal/TooltipModal";

import "./menu.scss";

const MenuComponent = (props) => {
  const [position, setPosition] = useState({
    top: 0,
    left:0,
  })
  const quickLiveRef = useRef();

    const updateTooltipPosition = () => {
    const element = quickLiveRef.current;
    const rect = element.getBoundingClientRect();
    const tooltipLeft = rect.left;

    // Calculate the initial tooltip top position
    const initialTooltipTop = rect.top - 5 ;

    // Calculate the adjusted tooltip top position based on screen height
    const screenHeight = window.innerHeight;
    const adjustedTooltipTop = screenHeight > 800 ? initialTooltipTop + 50 : initialTooltipTop;

      if (isFirefox) {
        setPosition({
          top: `${initialTooltipTop}px`,
          left: `${tooltipLeft + 230}px`,
        });
      } else {
        setPosition({
          top: `${initialTooltipTop}px`,
          left: `${tooltipLeft + 230}px`,
        });
      }
  };

  useEffect(() => {
    // Calculate the initial position
    updateTooltipPosition();

    // Update the position when the window is resized or scrolled
    window.addEventListener('resize', updateTooltipPosition);
    window.addEventListener('scroll', updateTooltipPosition);

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener('resize', updateTooltipPosition);
      window.removeEventListener('scroll', updateTooltipPosition);
    };
  }, [props.quickLiveTooltip]);



  const theme = useSelector((state) => state.local.theme);
  const handleMenu = (link) => {
    if (localStorage.getItem("user")) {
      props.history.push(link);
    } else {
      if (props.collapsed || isMobile) {
        props.toggleClick();
      }
      return throwNotification({
        type: "error",
        message: i18n.t("info.loginForAccess"),
      });
    }
  };
  let active;
  switch (props.location?.pathname) {
    case "/":
      active = "/";
      break;
    case "/advancedLive":
      active = "/advancedLive";
      break;
    case "/capture":
      active = "/capture";
      break;
    case "/director":
      active = "/director";
      break;
    case "/join":
      active = "/join";
      break;
    case "/director":
      active = "/director";
      break;
    default:
      active = "";
  }

  return (
    <Menu theme="light" mode={props.mode} className="desktopMenu">
        {/* ======================REDIRECTS TO FEEDS====================== */}
        <Menu.Item key="1" className={active === "/" ? "activeMenu" : ""}>
          <div
              onClick={() => {
                logEvent("FEED_CLICK");
                props.history.push("/");
              }}
          >
            {active === "/" ? (
                <i className="icon-view-events-active"></i>
            ) : (
                <i className="icon-view-events"></i>
            )}
            <span
                style={
                  !props.collapsed && props.mode === "inline"
                      ? { marginLeft: "13px" }
                      : {}
                }
            >
            {i18n.t("text.feeds")}
          </span>
            {props.collapsed ? (
                <div className="collapsedMenuTitle">{i18n.t("text.feeds")}</div>
            ) : null}
          </div>
        </Menu.Item>

        {/* ======================REDIRECTS TO QUICK LIVE====================== */}
      <Menu.Item key="2" className={active === "/capture" ? "activeMenu" : ""}>
        <TooltipModal
          visible={props.quickLiveTooltip}
          onNext={() => props.setTooltips({ quickLiveTooltip: false, RealTime: true })}
          setVisible={() => props.setTooltips(false)}
          top={position.top}
          left={position.left}
          arrowLeft
          title='Quick live:'
          note=' It is required to use the same account credentials when logging in across all intended devices to ensure a smooth Director experience.'
        >
          <>Select "Quick Live" and choose your cameras, Then click on <span style={{ textDecoration: 'underline', fontWeight: 700 }}>Switch to director </span>
            to start the live stream on web. On mobile, tap "Quick Live" to start previewing
          </>
          <div className="switch_director">
            <DirectIconSec />
            <span>Switch to Director</span>
          </div>
        </TooltipModal>
        <div ref={quickLiveRef}  onClick={() => handleMenu("/capture")}>
            {active === "/capture" ? (
            <QuickLiveIcon className={props.collapsed ? 'icon-collapsed' : 'icon-simple'} color={'#5c44af'} />
            ) : (
            <QuickLiveIcon className={props.collapsed ? 'icon-collapsed' : 'icon-simple'} color={theme === "dark" ? "#fff" : "#333"} />
            )}

            {props.collapsed ? (
                <div className="collapsedMenuTitle">Quick Live</div>
            ) : 
            <span style={!props.collapsed && props.mode === "inline" ? { marginLeft: "1px" }: {}} >
            Quick Live
          </span>
          }
          </div>
        </Menu.Item>

        {/* ======================REDIRECTS TO ADVANCED LIVE====================== */}
        <Menu.Item
            key="3"
            className={active === "/advancedLive" ? "activeMenu" : ""}
        >
          <div onClick={() => handleMenu("/advancedLive")}>
          {active === "/advancedLive" ? (
            <AdvancedLiveIcon className={props.collapsed ? 'icon-collapsed' : 'icon-simple'} color={'#5c44af'} />
            ) : (
            <AdvancedLiveIcon className={props.collapsed ? 'icon-collapsed' : 'icon-simple'} color={theme === "dark" ? "#fff" : "#333"} />
            )}

            {props.collapsed ? (
              <div className="collapsedMenuTitle">Advance Live</div>
            ) :
            <span>Advance Live</span>
            }

          </div>
        </Menu.Item>

        {/* ======================REDIRECTS TO DIRECTOR LIVE====================== */}
        <Menu.Item
          key="4"
          className={active === "/director" ? "activeMenu" : ""}
        >
          <div onClick={() => handleMenu("/director")}>
            {active === "/director" ? (
            <>
              <DirectorIcon className={props.collapsed ? 'icon-collapsed' : 'icon-simple'} color={'#5c44af'} />
            </>
            ) : (
              <>
                <DirectorIcon className={props.collapsed ? 'icon-collapsed' : 'icon-simple'} color={theme === "dark" ? "#fff" : "#333"} />
              </>
            )}

            {props.collapsed ? (
              <div className="collapsedMenuTitle">Director</div>
            ) :
              <span>Director</span>
            }
          </div>
        </Menu.Item>
        {/* ======================REDIRECTS TO JOIN PAGE====================== */}
        <Menu.Item key="5" className={active === "/join" ? "activeMenu" : ""}>
          <div onClick={() => handleMenu("/join")}>
            {active === "/join" ? (
                <i className="icon-teamlive-active"></i>
            ) : (
                <i className="icon-teamlive"></i>
            )}

            <span
                style={
                  !props.collapsed && props.mode === "inline"
                      ? { marginLeft: "8px" }
                      : {}
                }
            >
            Join
          </span>
            {props.collapsed ? (
                <div className="collapsedMenuTitle">Join</div>
            ) : null}
          </div>
      </Menu.Item>

      {/* FOR HANDLING TOOLTIPS */}
    </Menu>
  );
};

export default MenuComponent;
